import { WrappedFieldProps } from 'redux-form'

import React from 'react'
import ReactSelect from 'react-select'

type Option = { [valueOrLabelKey: string]: number | string }

type ReactSelectProps = {
  labelKey?: string
  multi?: boolean
  options: Option[]
  valueKey?: string
}

type Props = {
  withAll?: boolean
} & React.InputHTMLAttributes<HTMLInputElement> &
  ReactSelectProps &
  WrappedFieldProps

const Select = (props: Props) => {
  const labelKey = props.labelKey || 'label'
  const valueKey = props.valueKey || 'value'

  const allOption: Option = { [valueKey]: 0, [labelKey]: 'All' }

  const {
    input: { value, onChange, onBlur },
    meta: { error, touched },
    options,
    multi,
    withAll,
    ...otherProps
  } = props

  const createSelect = () => {
    if (multi) {
      if (withAll) {
        if (
          value === '' ||
          value.length === 0 ||
          value.length === options.length
        ) {
          return (
            <ReactSelect
              multi
              options={options}
              value={[allOption]}
              onChange={selected => {
                // @ts-ignore
                onBlur()

                if (selected.length === 0) {
                  return onChange(options)
                }

                onChange(selected.slice(1))
              }}
              {...otherProps}
            />
          )
        }

        const values = value.map(x => x[valueKey])

        return (
          <ReactSelect
            multi
            options={[allOption, ...options]}
            value={options.filter(o => values.includes(o[valueKey]))}
            onChange={selected => {
              // @ts-ignore
              onBlur() // toggle 'touched: true'

              if (
                selected.length === 0 ||
                selected[selected.length - 1][valueKey] === allOption[valueKey]
              ) {
                return onChange(options)
              }

              onChange(selected)
            }}
            {...otherProps}
          />
        )
      }

      const values = value ? value.map(x => x[valueKey]) : []

      return (
        <ReactSelect
          multi
          options={options}
          value={options.filter(o => values.includes(o[valueKey]))}
          onChange={selected => {
            onChange(selected)
            // @ts-ignore
            onBlur() // toggle 'touched: true'
          }}
          {...otherProps}
        />
      )
    }

    return (
      <ReactSelect
        options={options}
        value={options.find(o => o[valueKey] === value[valueKey])}
        onChange={selected => {
          onChange(selected)
          // @ts-ignore
          onBlur() // toggle 'touched: true'
        }}
        {...otherProps}
      />
    )
  }

  return (
    <div className={`dd-form-group${touched && error ? ' error' : ''}`}>
      {createSelect()}
      {touched && error && <span className="dd-error-feedback">{error}</span>}
    </div>
  )
}

export default Select
