export const url = (...args) => {
  return [...args].reduce((s, c) => s.replace(/:\w+/, c))
}

export const invertEditUrl = (...args) => {
  return [...args].reduce((s, c) => {
    const findRoot = s.replace('/edit', '').lastIndexOf('/')
    return s.includes('/edit') ? s.slice(0, findRoot) : s
  })
}

export const formatFrame = frame => {
  const start = frame.indexOf('href="') + 6
  const end = frame.indexOf('">')

  return frame.substring(start, end)
}
