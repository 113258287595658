import qs from 'qs'
import Cookies from 'universal-cookie'
import { Auth } from '../auth'

const cookies = new Cookies()

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

type Request = {
  method: string
  endpoint?: string
  url?: string
  body?: {}
  params?: {}
  itemToDelete?: string
  ContentType?: string
  qsFormat?: boolean
}

export class FetchWrapper {
  auth: Auth
  baseUrl: string

  constructor(auth: Auth, baseUrl: string) {
    this.auth = auth
    this.baseUrl = baseUrl
  }

  async request({
    method,
    endpoint,
    url,
    body,
    params,
    itemToDelete,
  }: Request) {
    if (!method) {
      throw new Error('method is required')
    }

    if (!endpoint && !url) {
      throw new Error('endpoint or url is required')
    }

    const stringifiedParams = params ? `?${qs.stringify(params)}` : ''

    const URL = url || this.baseUrl + endpoint + stringifiedParams
    const refreshToken = cookies.get('refresh_token')

    const response = await fetch(URL, {
      method,
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    })

    // Successful DELETE
    if (response.status === 204) {
      return null
    }

    const results = await response.json()

    if (response.status >= 300) {
      if (response.status === 401) {
        throw new (function() {
          this.code = 401
        })()
      }

      if (results && results.error) {
        throw new Error(results.error)
      }

      if (results && results.data && results.error) {
        throw new Error(results.error)
      }
      // @ts-ignore
      throw new Error(response.status)
    }

    if (results.length >= 0) {
      return {
        items: results,
      }
    }

    if (typeof results === 'object' && results.slug) {
      return {
        items: [results],
      }
    }

    if (results.message) {
      return {
        item: itemToDelete,
      }
    }
  }

  get(args: {}) {
    return this.request({ method: METHODS.GET, ...args })
  }

  post(args: {}) {
    return this.request({ method: METHODS.POST, ...args })
  }

  patch(args: {}) {
    return this.request({ method: METHODS.PATCH, ...args })
  }

  put(args: {}) {
    return this.request({ method: METHODS.PUT, ...args })
  }

  del(args: {}) {
    return this.request({ method: METHODS.DELETE, ...args })
  }
}
