import { WrappedFieldProps } from 'redux-form'

import React, { PureComponent } from 'react'

import EditorJs from '@natterstefan/react-editor-js'
import EditorJS from '@editorjs/editorjs'
import { EDITOR_JS_TOOLS } from './constants'

import './index.css'

export interface EditorJsProps {
  tools?: EditorJS.EditorConfig['tools']

  instanceRef?: (instance: EditorJS) => void
}

export type Props = Readonly<EditorJS.EditorConfig> &
  Readonly<EditorJsProps> &
  WrappedFieldProps

type State = {
  currentData: any
}

class EditorJsContainer extends PureComponent<Props, State> {
  state = {
    currentData: null,
  }

  editorInstance: any

  handleChange = () => {
    const {
      input: { onChange },
    } = this.props

    this.editorInstance.save().then(data => {
      onChange(data)
    })
  }

  render() {
    const { data } = this.props

    return (
      <>
        <div className="editorjs-container">
          <EditorJs
            editorInstance={instance => (this.editorInstance = instance)}
            tools={EDITOR_JS_TOOLS}
            onChange={this.handleChange}
            data={data}
          />
        </div>
      </>
    )
  }
}
export default EditorJsContainer
