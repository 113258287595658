const LOGIN = '/'
const CASES = '/casos'
const MOMS = '/defensoras'
const MULTIMEDIA = '/multimedia'
const FLASH_NEWS = '/alertas'
const FLASH_NEWS_ITEM = '/:slug'
const CONTACTS = '/contacts'
const CASE_ID = '/:casoSlug'
const MOM_ID = '/:slug'
const MULTIMEDIA_ITEM = '/:slug'
const NEW = '/new'
const EDIT = '/edit'

export const URLS = Object.freeze({
  /* prettier-ignore */
  LOGIN:                     LOGIN,
  /* prettier-ignore */
  CASES:                     CASES,
  /* prettier-ignore */
  CASE:                      CASES + CASE_ID + EDIT,
  /*prettier-ignore */
  NEW_CASE:                  CASES + NEW,
  /*prettier-ignore */
  MOMS:                       MOMS,
  /*prettier-ignore */
  MOM:                        MOMS + MOM_ID + EDIT,
  /*prettier-ignore */
  NEW_MOM:                    MOMS + NEW,
  /*prettier-ignore */
  MULTIMEDIA:                 MULTIMEDIA,
  /*prettier-ignore */
  FLASH_NEWS:                 FLASH_NEWS,
  /*prettier-ignore */
  CONTACTS:                   CONTACTS,
  /*prettier-ignore */

  MULTIMEDIA_ITEM:            MULTIMEDIA + MULTIMEDIA_ITEM + EDIT,
  /*prettier-ignore */
  FLASH_NEWS_ITEM:            FLASH_NEWS + FLASH_NEWS_ITEM + EDIT
})

export const PAGES_IDS = {
  LOGIN: { id: 'login', name: 'Login' },
  CASES: { id: URLS.CASES.replace('/', ''), name: 'Casos' },
  MOMS: { id: URLS.MOMS.replace('/', ''), name: 'Defensoras' },
  MULTIMEDIA: {
    id: URLS.MULTIMEDIA.replace('/', ''),
    name: 'Multimedia',
  },
  FLASH_NEWS: { id: URLS.FLASH_NEWS.replace('/', ''), name: 'Alertas' },
  FLASH_NEWS_ITEM: {
    id: URLS.FLASH_NEWS_ITEM.replace('/', ''),
    name: 'Alerta',
  },
  CASE: { id: URLS.CASE.replace('/', ''), name: 'Caso' },
  MOM: { id: URLS.MOM.replace('/', ''), name: 'Defensora' },
  MULTIMEDIA_ITEM: {
    id: URLS.MULTIMEDIA_ITEM.replace('/', ''),
    name: 'Element',
  },
}

export const LOCATIONS = [
  {
    label: 'Aguascalientes',
    value: 'AG',
  },
  {
    label: 'Baja California',
    value: 'BN',
  },
  {
    label: 'Baja California Sur',
    value: 'BS',
  },
  { label: 'Campeche', value: 'CM' },
  { label: 'Chiapas', value: 'CP' },
  { label: 'Chihuahua', value: 'CH' },
  {
    label: 'Ciudad de México',
    value: 'DF',
  },
  { label: 'Coahuila', value: 'CA' },
  { label: 'Colima', value: 'CL' },
  { label: 'Durango', value: 'DU' },
  { label: 'Guanajuato', value: 'GJ' },
  { label: 'Guerrero', value: 'GR' },
  { label: 'Hidalgo', value: 'HI' },
  { label: 'Jalisco', value: 'JA' },
  { label: 'Estado de México', value: 'MX' },
  { label: 'Michoacán', value: 'MC' },
  { label: 'Morelos', value: 'MR' },
  { label: 'Nayarit', value: 'NA' },
  {
    label: 'Nuevo León',
    value: 'NL',
  },
  { label: 'Oaxaca', value: 'OA' },
  { label: 'Puebla', value: 'PU' },
  { label: 'Querétaro', value: 'QE' },
  {
    label: 'Quintana Roo',
    value: 'QR',
  },
  {
    label: 'San Luis Potosí',
    value: 'SL',
  },
  { label: 'Sinaloa', value: 'SI' },
  { label: 'Sonora', value: 'SO' },
  { label: 'Tabasco', value: 'TB' },
  { label: 'Tamaulipas', value: 'TM' },
  { label: 'Tlaxcala', value: 'TL' },
  { label: 'Veracruz', value: 'VE' },
  { label: 'Yucatán', value: 'YU' },
  { label: 'Zacatecas', value: 'ZA' },
]

export const TIPO_FAMILIARES = [
  {
    value: 1,
    label: 'Madre',
  },
  {
    value: 2,
    label: 'Madre y Padre',
  },
  {
    value: 3,
    label: 'Hermana',
  },
  {
    value: 4,
    label: 'Abuelo y Abuela',
  },
  {
    value: 5,
    label: 'Padre',
  },

  {
    value: 6,
    label: 'Hermano',
  },

  {
    value: 7,
    label: 'Abuela',
  },

  {
    value: 8,
    label: 'Tía',
  },
  {
    value: 9,
    label: 'Familiar',
  },
  {
    value: 10,
    label: 'Amiga',
  },
  {
    value: 11,
    label: 'Defensora',
  },
  {
    value: 12,
    label: 'Defensor',
  },
  {
    value: 13,
    label: 'Tentativa de feminicidio',
  },
]

export const MULTIMEDIA_TYPES = {
  video: 'Video',
  postal: 'Postal',
  infografia: 'Infografía',
}
