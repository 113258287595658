import { FormField } from '../../types'
import { AppState, tiposDeCasosActions } from '../../state'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { InjectedFormProps } from 'redux-form'
import ReactPlaceholder from 'react-placeholder'

import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form'

import {
  renderInput,
  renderFileDrop,
  renderInputArray,
  Period,
  TableEditDelete,
  renderTextarea,
  DatePicker,
  renderSelect,
  renderSwitch,
} from '../Fields'

import { EditorJs, FormatedDescriptionText } from '../index'
import Button from '../Button'

import './index.css'
import { isEqual } from 'lodash'

const CASE_TYPES_COLUMNS = [
  {
    id: 'name',
    label: 'Tipos De Caso',
  },
  {
    id: 'edit',
    label: 'Edit',
  },
  {
    id: 'delete',
    label: 'Borrar',
  },
]

type StateProps = ReturnType<typeof mapStateToProps>
type OwnProps = {
  name: string
  formFields: FormField[]
  createItem?: () => void
  cancelForm: (status: boolean) => void
  editItem?: () => void
  deleteItem?: () => void
  disableButton?: boolean
  initialValues: any
  closeModal: boolean
}

type ActionsProps = typeof mapActionsToProps

type Props = StateProps & OwnProps & ActionsProps

type State = {
  openCaseTypeCreator: boolean
  view: boolean
  historia: boolean
}
class FormCreateOrEdit extends Component<
  Props & InjectedFormProps<FormData, Props>,
  State
> {
  interval?: number

  state = {
    openCaseTypeCreator: false,
    view: false,
    historia: false,
  }

  componentDidMount() {
    this.clearInterval()
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialized, showCancel } = this.props
    const { historia } = this.state

    if (
      (!showCancel && !prevProps.initialized && initialized && !historia) ||
      showCancel
    ) {
      if (isEqual(initialValues.historia, prevProps.initialValues.historia)) {
        this.setInterval()
      }
    }
  }

  setInterval = () => {
    this.interval = window.setInterval(() => {
      this.setState(() => ({
        historia: true,
      }))
    }, 1000)
  }

  clearInterval = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  editRow = (value: string) => {
    const nombre = value
    this.props.createTiposDeCaso(nombre)
  }

  deleteRow = (slug: string) => {
    this.props.deleteTiposDeCaso(slug)
  }

  render() {
    const {
      formFields,
      name,
      createItem,
      cancelForm,
      disableButton,
      publicText,
      initialValues,
      editItem,
      imageToShow,
      publicBtn,
      deleteItem,
      viewText,
      showCancel,
    } = this.props
    const { openCaseTypeCreator, historia } = this.state

    const formName = name.toLowerCase()

    return (
      <div className="shape-content">
        <div className="shape-row">
          <div className="shape-col-1"></div>
          <div className="shape-col-auto">
            {formFields
              ? formFields.map((field, key) => {
                  if (field.type === 'select') {
                    return (
                      <div key={key} className="form-row">
                        <div className="form-group">
                          <label className="input-name">{field.name}</label>

                          {field.newCaseType ? (
                            <div className="tipoDeCaso-btn">
                              <span
                                onClick={() =>
                                  this.setState({
                                    openCaseTypeCreator: true,
                                  })
                                }
                              >
                                Agregar tipo caso
                              </span>
                              {openCaseTypeCreator && formName ? (
                                <TableEditDelete
                                  name="caseTypes"
                                  formName={formName}
                                  columns={CASE_TYPES_COLUMNS}
                                  options={field.options ? field.options : []}
                                  editRow={this.editRow}
                                  deleteRow={this.deleteRow}
                                />
                              ) : null}
                            </div>
                          ) : null}

                          <Field
                            className="input-select"
                            label={field.name}
                            name={field.fieldName}
                            component={renderSelect}
                            options={field.options}
                            isSearchable
                            isClearable
                            disabled={field.disabled}
                          />
                        </div>
                      </div>
                    )
                  }

                  if (field.type === 'period') {
                    return (
                      <div className="form-row" key={key}>
                        <Field name={field.fieldName} component={Period} />
                      </div>
                    )
                  }

                  if (field.type === 'date') {
                    return (
                      <div className="form-row" key={key}>
                        <Field
                          label={field.name}
                          name={field.fieldName}
                          component={DatePicker}
                        />
                      </div>
                    )
                  }

                  if (field.type === 'text') {
                    return (
                      <div className="form-row" key={key}>
                        <Field
                          label={field.name}
                          name={field.fieldName}
                          component={renderInput}
                        />
                      </div>
                    )
                  }

                  if (field.type === 'textarea' && !field.view) {
                    return (
                      <div className="form-row" key={key}>
                        <Field
                          label={field.name}
                          name={field.fieldName}
                          component={renderTextarea}
                        />
                      </div>
                    )
                  }

                  if (field.type === 'textarea' && field.view) {
                    return (
                      <div key={key} className="form-group">
                        <div
                          className="view"
                          onClick={() =>
                            this.setState({
                              view: !this.state.view,
                            })
                          }
                        >
                          {!this.state.view ? 'Visualizar' : 'Editar'}
                        </div>

                        {!this.state.view ? (
                          <Field
                            hint={field.hint}
                            label={field.name}
                            name={field.fieldName}
                            component={renderTextarea}
                          />
                        ) : (
                          <FormatedDescriptionText
                            className={'quote'}
                            text={viewText}
                          />
                        )}
                      </div>
                    )
                  }

                  if (field.type === 'image') {
                    return (
                      <div key={key} className="form-row">
                        <div className="form-group">
                          <label className="input-name">{field.name}</label>
                          <Field
                            name={field.fieldName}
                            component={renderFileDrop}
                            image={imageToShow}
                          />
                        </div>
                      </div>
                    )
                  }

                  if (field.type === 'editor') {
                    return (
                      <div key={key} className="form-row">
                        <div className="form-group">
                          <label className="input-name">{field.name}</label>
                          <ReactPlaceholder
                            type="rect"
                            rows={20}
                            ready={historia}
                          >
                            <Field
                              label={field.name}
                              name={field.fieldName}
                              component={EditorJs}
                              data={
                                (initialValues && initialValues.historia) ||
                                null
                              }
                            />
                          </ReactPlaceholder>
                        </div>
                      </div>
                    )
                  }

                  if (field.type === 'linkArray' && field.fieldName) {
                    return (
                      <div className="form-row" key={key}>
                        <FieldArray
                          name="material"
                          component={renderInputArray}
                        />
                      </div>
                    )
                  }

                  if (field.type === 'link') {
                    return (
                      <div key={key} className="form-row">
                        <Field
                          label={field.name}
                          name={field.fieldName}
                          component={renderInput}
                        />
                      </div>
                    )
                  }

                  if (field.type === 'delete' && deleteItem) {
                    return (
                      <div key={key} className="form-row delete">
                        <Button
                          text={field.name || ''}
                          size="sm"
                          color="dark-purple"
                          disabled={!deleteItem}
                          onClick={() => (deleteItem ? deleteItem() : null)}
                        />
                      </div>
                    )
                  }
                  return null
                })
              : null}
          </div>
          <div className="shape-col-1"></div>
        </div>
        <div className="form-fixed-buttons">
          <div className="shape-content">
            <div className="shape-row">
              <div className="shape-col-2">
                {publicBtn ? (
                  <Field
                    label={publicText}
                    name="publicar"
                    component={renderSwitch}
                  />
                ) : null}
              </div>
              <div className="shape-col-auto">
                {showCancel ? (
                  <Button
                    text="Cancelar"
                    size="xs"
                    color="primary"
                    onClick={() => cancelForm(false)}
                  />
                ) : null}
                {createItem ? (
                  <Button
                    text="Crear"
                    size="xs"
                    disabled={disableButton}
                    color="orange"
                    onClick={createItem}
                    loading={false}
                  />
                ) : null}

                {editItem ? (
                  <Button
                    text="Guardar"
                    size="xs"
                    disabled={disableButton}
                    color="orange"
                    onClick={editItem}
                    loading={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const reduxFormContainer = reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  // @ts-ignore
})(FormCreateOrEdit)

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { name, initialValues, formFields } = ownProps

  const formValuesSelector = formValueSelector(name)

  const findFieldTypeImage = formFields.length
    ? formFields.find(field => field.type === 'image' || field.type === 'foto')
    : initialValues.imagen

  const imageFileName = findFieldTypeImage ? findFieldTypeImage.fieldName : ''

  const imageToShow = imageFileName
    ? formValuesSelector(state, imageFileName)
    : ''

  const viewText = formValuesSelector(state, 'description')

  const showCancel = name.includes('Edit')

  return {
    form: name,
    initialValues,
    showCancel,
    imageToShow: imageToShow,
    viewText: viewText
      ? viewText
          .replace(/#1#/g, '--split--<MARK>')
          .replace(/#2#/g, '--split--')
          .split('--split--')
          .map(markText => {
            if (markText.includes('<MARK>')) {
              return {
                type: 'mark',
                color: 'violet',
                text: markText.replace(/<MARK>/g, ''),
              }
            } else {
              return {
                type: 'p',
                text: markText,
              }
            }
          })
      : '',
    publicBtn: name.includes('Defensora') || name.includes('Caso'),
    publicText: formValuesSelector(state, 'publicar')
      ? 'Público'
      : 'No público',
  }
}

const mapActionsToProps = {
  createTiposDeCaso: tiposDeCasosActions.createResource,
  deleteTiposDeCaso: tiposDeCasosActions.deleteResource,
}

export default connect(mapStateToProps, mapActionsToProps)(reduxFormContainer)
