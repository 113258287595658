import React, { PureComponent } from 'react'

import { EditSection, error, success } from '../Components'
import { connect } from 'react-redux'
import { RouterHistory, Match } from 'react-router-dom'
import { LOCATIONS, URLS } from '../constants'
import {
  AppState,
  casoActions,
  tiposDeCasosActions,
  defensorasActions,
} from '../state'
import { getFormValues } from 'redux-form'
import moment from 'moment'
import 'moment/locale/es'
import 'moment-timezone'

type OwnProps = {
  history: RouterHistory
  match: Match
}

type IValForm = {
  victimaNombre: string
  tipo: {
    value: string
    label: string
  }
  fechaDeNacimiento: moment.Moment
  historia: {
    time: number
    version: string
    blocks: any[]
  }
  intro: string
  description: string
  imagen: string
  lugarDeNacimiento: { value: string }
  fechaDeFeminicidio: moment.Moment
  lugarDeFeminicidio: { value: string }
  publicar: boolean
  material: {
    fecha: moment.Moment
    origen: string
    titulo: string
    url: string
  }[]
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

class Case extends PureComponent<Props> {
  componentDidMount() {
    const { tiposDeCasos, slug } = this.props

    if (tiposDeCasos && !tiposDeCasos.length) {
      this.props.fetchTiposDeCasos()
    }
    if (slug) {
      this.props.fetchCaso({ slug })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiError, isUpdating, history, isDeleting, slug } = this.props

    if (!isUpdating && prevProps.isUpdating) {
      if (apiError) {
        error('¡¡Upps!!!, Ha habido un error al actualizar correctamente.')
      } else {
        this.props.fetchCaso({ slug })
        success('Elemento editado correctamente.')
        history.push(URLS.CASES)
      }
    }

    if (!isDeleting && prevProps.isDeleting) {
      success('Caso borrado correctamente.')
      history.push(URLS.CASES)
    }
  }

  editItem = () => {
    const { formValues, editCaso, caso, tiposDeCasos } = this.props

    const casoEdit = formValues &&
      formValues.tipo &&
      formValues.lugarDeNacimiento && {
        foto: formValues.imagen,
        tipoCaso: formValues.tipo.label,
        titulo: formValues.victimaNombre,
        nombre: formValues.victimaNombre,
        defensores: caso.defensores_id,
        historia: formValues.historia,
        intro: formValues.intro,
        descripcion: formValues.description,
        feminicidio: {
          lugar: formValues.lugarDeFeminicidio.value,
          fecha: formValues.fechaDeFeminicidio.utc().format(),
        },
        victima: {
          nombre: formValues.victimaNombre,
          fecha_nacimiento: formValues.fechaDeNacimiento.utc().format(),
          lugar_nacimiento: formValues.lugarDeNacimiento.value,
        },
        publicado: formValues.publicar,
        material: formValues.material.map(m => ({
          origen: `${m.fecha ? m.fecha.format('DD MMMM YYYY') : ''}/ ${
            m.origen
          }`,
          titulo: m.titulo,
          url: m.url,
        })),
      }

    const tipo =
      caso && tiposDeCasos.find(c => c.nombre === caso.tipoCaso_id.nombre)

    const tipoSlug = tipo ? tipo.slug : ''

    editCaso({
      paretSlug: tipoSlug,
      slug: caso.slug,
      body: casoEdit,
    })
  }

  goToPrevious = () => {
    const { history } = this.props

    if (history.location.key) {
      history.goBack()
    } else {
      history.push(URLS.CASES)
    }
  }

  delete = () => {
    const { deleteCaso, caso, tiposDeCasos } = this.props
    const tipo =
      caso && tiposDeCasos.find(c => c.nombre === caso.tipoCaso_id.nombre)

    const tipoSlug = tipo ? tipo.slug : ''
    deleteCaso({ paretSlug: tipoSlug, slug: caso.slug })
  }

  render() {
    const { disableButton, tiposDeCasos, initialValues } = this.props

    return (
      <div className="shape-content">
        <div className="shape-row">
          <div className="shape-col-auto">
            <EditSection
              initialValues={initialValues}
              routeHistory={this.props.history}
              name="Caso"
              cancelForm={() => this.goToPrevious()}
              editItem={this.editItem}
              disableButton={!!disableButton}
              deleteItem={this.delete}
              formFields={[
                {
                  type: 'image',
                  name: 'Imagen Principal',
                  fieldName: 'imagen',
                },
                {
                  type: 'select',
                  name: 'Tipo de Caso',
                  fieldName: 'tipo',
                  options: tiposDeCasos.map(t => ({
                    value: t.slug,
                    label: t.nombre,
                  })),
                  newCaseType: 'Tipo de Caso',
                },

                {
                  type: 'text',
                  name: 'Nombre de la Víctima',
                  fieldName: 'victimaNombre',
                },
                {
                  type: 'select',
                  name: 'Lugar de Nacimiento',
                  fieldName: 'lugarDeNacimiento',
                  options: LOCATIONS,
                },
                {
                  type: 'date',
                  name: 'Fecha de Nacimiento',
                  fieldName: 'fechaDeNacimiento',
                },
                {
                  type: 'textarea',
                  name: 'Intro',
                  fieldName: 'intro',
                },
                {
                  type: 'textarea',
                  name: 'Descripcion',
                  fieldName: 'description',
                  hint:
                    'Para subrayar agrupa el texto  siguiente forma "#1# Tu texto #2#"',
                  view: true,
                },
                {
                  type: 'select',
                  name: 'Lugar de Feminicidio',
                  fieldName: 'lugarDeFeminicidio',
                  options: LOCATIONS,
                },
                {
                  type: 'date',
                  name: 'Fecha de Feminicidio',
                  fieldName: 'fechaDeFeminicidio',
                },
                { type: 'editor', name: 'Historia', fieldName: 'historia' },
                {
                  type: 'linkArray',
                  name: 'Materiale Hemerográfico',
                  fieldName: 'material',
                },
                {
                  type: 'delete',
                  name: 'Borrar Caso',
                  fieldName: 'delete',
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { match } = ownProps

  const slug = match.params.casoSlug

  const formValues = getFormValues('CasoFormEdit')(state) as IValForm
  const disableButton =
    formValues &&
    formValues.tipo &&
    formValues.lugarDeNacimiento &&
    formValues.lugarDeFeminicidio &&
    formValues.fechaDeNacimiento &&
    formValues.fechaDeFeminicidio &&
    formValues.tipo.label &&
    formValues.victimaNombre

  const caso = state.caso.items && state.caso.items[0]
  const tipo =
    caso &&
    state.tiposDeCasos.items.find(c => c.nombre === caso.tipoCaso_id.nombre)

  return {
    caso,
    isUpdating: state.caso.isUpdating,
    isDeleting: state.caso.isDeleting,
    apiError: state.caso.APIError,
    tiposDeCasos: state.tiposDeCasos.items,
    isCreatingtipoCaso: state.tiposDeCasos.isCreating,
    formValues,
    disableButton: !disableButton,
    slug,
    initialValues: {
      victimaNombre: caso ? caso.nombre : '',
      imagen: caso ? caso.foto : '',
      lugarDeNacimiento: caso
        ? LOCATIONS.find(l => l.value === caso.victima.lugar_nacimiento)
        : {},
      fechaDeNacimiento: caso
        ? moment(caso.victima.fecha_nacimiento)
            .locale('es')
            .tz('America/Mexico_City')
        : null,
      intro: caso ? caso.intro : '',
      description: caso ? caso.descripcion : '',
      lugarDeFeminicidio: caso
        ? LOCATIONS.find(l => l.value === caso.feminicidio.lugar)
        : {},
      fechaDeFeminicidio: caso
        ? moment(caso.feminicidio.fecha)
            .locale('es')
            .tz('America/Mexico_City')
        : null,
      historia: caso ? caso.historia : null,
      tipo: tipo
        ? {
            value: tipo.slug,
            label: tipo.nombre,
          }
        : {},
      material:
        caso && caso.material && caso.material.length
          ? caso.material.map(m => {
              const split = m.origen.split('/ ')

              return {
                fecha:
                  split[0] && split[0] !== 'Fecha invalida'
                    ? moment(split[0])
                    : null,
                origen: split[1],
                titulo: m.titulo,
                url: m.url,
              }
            })
          : [],

      publicar: caso && !!caso.publicado,
    },
  }
}
const mapActionsToProps = {
  fetchCaso: casoActions.fetchResources,
  deleteCaso: casoActions.deleteResource,
  fetchTiposDeCasos: tiposDeCasosActions.fetchResources,
  editCaso: casoActions.updateResource,
  fetchDefensoras: defensorasActions.fetchResources,
}

export default connect(mapStateToProps, mapActionsToProps)(Case)
