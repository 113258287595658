import React, { Component } from 'react'
import { connect } from 'react-redux'

import { reduxForm, Field, formValueSelector } from 'redux-form'
import Cookies from 'universal-cookie'

import getConfig from '../config'
import { Auth } from '../auth'

import { Location, RouterHistory } from 'react-router-dom'

import { renderInput } from '../Components/Fields'
import { AppState } from '../state'
import { Button } from '../Components'
import { URLS } from '../constants'

import { logoLg } from '../Images'

type StateProps = ReturnType<typeof mapStateToProps>

type OwnProps = { title: string; location: Location; history: RouterHistory }

type Props = StateProps & OwnProps

const cookies = new Cookies()

const auth = new Auth(getConfig())

const STATUS = {
  OK: 1,
  LOADING: 2,
  ERROR: 3,
}

type State = {
  status: number
  errorCode: any
}

class Home extends Component<Props, State> {
  state = {
    status: STATUS.OK,
    errorCode: null,
  }

  componentWillMount() {
    const refreshToken = cookies.get('refresh_token')
    auth
      .authenticate(refreshToken)
      .then(isAuth => {
        this.props.history.push(URLS.CASES)
      })
      .catch(error => {
        cookies.remove('access_token')
        cookies.remove('refresh_token')
      })
  }

  login = async () => {
    const { email, password } = this.props

    this.setState({ status: STATUS.LOADING })

    try {
      const accessToken = await auth.login(email, password)

      cookies.set('access_token', accessToken.token, {
        path: '/',
      })

      this.setState({ status: STATUS.OK, errorCode: null })

      this.redirect()
    } catch (error) {
      this.setState({ status: STATUS.ERROR, errorCode: error.code })
    }
  }

  redirect = async () => {
    const accessToken = cookies.get('access_token')

    if (!!accessToken) {
      try {
        const refreshToken = await auth.authenticateAccesToken(accessToken)

        cookies.set('refresh_token', refreshToken.token, {
          path: '/',
        })

        this.setState({ status: STATUS.OK, errorCode: null })
        this.props.history.push(URLS.CASES)
      } catch (error) {
        this.setState({ status: STATUS.ERROR, errorCode: error.code })
      }
    }
  }

  render() {
    const { disabled } = this.props

    return (
      <div className="logIn">
        <div className="shape-content">
          <div className="shape-row">
            <div className="shape-col-auto"></div>
            <div className="shape-col-auto ">
              <div className="center-shape-container">
                <div className="center-shape">
                  <div className="shape-card">
                    <div className="shape-card-login-image">
                      <img src={logoLg} alt="nvavj-logo" />
                    </div>
                    <Field
                      name="email"
                      label="E-mail"
                      component={renderInput}
                    />
                    <Field
                      name="password"
                      label="Contraseña"
                      type="password"
                      component={renderInput}
                    />
                    <Button
                      text="Login"
                      color="orange"
                      disabled={disabled}
                      onClick={() => this.login()}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="shape-col-auto"></div>
          </div>
        </div>
      </div>
    )
  }
}

const loginSelector = formValueSelector('logInFrom')
const reduxFormContainer = reduxForm({
  form: 'logInFrom',
  enableReinitialize: true,
  // @ts-ignore
})(Home)

const mapStateToProps = (state: AppState) => {
  return {
    email: loginSelector(state, 'email'),
    password: loginSelector(state, 'password'),
    disabled:
      !loginSelector(state, 'email') && !loginSelector(state, 'password'),
  }
}

export default connect(mapStateToProps)(reduxFormContainer)
