import React, { Component } from 'react'
import { RouterHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { Section, success, error } from '../Components'
import { URLS, MULTIMEDIA_TYPES } from '../constants'
import { AppState, multimediaActions } from '../state'

const COLUMNS = [
  { id: 'titulo', label: 'Título' },
  { id: 'serie', label: 'Serie' },
  { id: 'tipo', label: 'Tipo' },
  { id: 'editar', label: 'Editar' },
]

type IValForm = {
  titulo: string
  tipo: {
    value: string
    label: string
  }
  serie: string
  description: string
  link: string
}

type OwnProps = { history: RouterHistory }

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

type State = {
  closeModal: boolean
}
class Multimedia extends Component<Props, State> {
  state = {
    closeModal: false,
  }

  componentDidMount() {
    this.props.fetchMultimedia()
  }

  create = () => {
    const { formValues, createMultimedia } = this.props
    const { tipo, ...args } = formValues

    const multimedia = {
      ...args,
      tipo: tipo.value,
    }
    createMultimedia(multimedia)
  }

  componentDidUpdate(prevProps, prevState) {
    const { isCreatingMultimedia, apiError, showHint } = this.props
    const { closeModal } = this.state

    if (showHint && !prevProps.showHint) {
      error('El link del video debe ser de youtube o vimeo')
    }

    if (!isCreatingMultimedia && prevProps.isCreatingMultimedia) {
      if (apiError) {
        this.setState({ closeModal: true }, () =>
          error('¡¡Upps!!!, Ha habido un error al crear este elemento.')
        )
      }
      this.props.fetchMultimedia()
      this.setState({ closeModal: true }, () =>
        success('Elemento creado con éxito')
      )
    }
    if (closeModal && !prevState.closeModal) {
      this.setState({ closeModal: false })
    }
  }
  render() {
    const { disableButton, multimedia, showLink, isfetching } = this.props
    const { closeModal } = this.state
    const link = showLink
      ? { type: 'text', name: 'Link', fieldName: 'link' }
      : {}
    const description = !showLink
      ? { type: 'textarea', name: 'Descripcion', fieldName: 'descripcion' }
      : {}

    return (
      <Section
        name="Multimedia"
        item="multimedia"
        itemURL={URLS.MULTIMEDIA_ITEM}
        routeHistory={this.props.history}
        formFields={[
          {
            type: 'image',
            name: 'Imagen Principal',
            fieldName: 'imagen',
          },
          { type: 'text', name: 'Serie', fieldName: 'serie' },
          { type: 'text', name: 'Titulo', fieldName: 'titulo' },
          {
            type: 'select',
            name: 'Tipo',
            fieldName: 'tipo',
            options: [
              {
                value: 'video',
                label: 'Video',
              },
              {
                value: 'postal',
                label: 'Postal',
              },
              {
                value: 'infografia',
                label: 'Infografía',
              },
            ],
          },

          description,
          link,
        ]}
        columns={COLUMNS}
        tableData={multimedia}
        disableButton={disableButton}
        createItem={this.create}
        closeModal={closeModal}
        isFetching={isfetching}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('MultimediaForm')(state) as IValForm
  const showLink =
    formValues && formValues.tipo && formValues.tipo.value === 'video'

  const showHint =
    formValues && formValues.link
      ? (formValues.link && formValues.link.includes('youtube')) ||
        (formValues.link && formValues.link.includes('vimeo'))
      : true

  const disableIfLinkIsWrong = showLink
    ? formValues && formValues.link && showHint
    : !showLink

  const disableButton =
    formValues && formValues.titulo && formValues.tipo && disableIfLinkIsWrong

  return {
    formValues,
    showLink,
    showHint: !showHint,
    disableButton: !disableButton,
    isCreatingMultimedia: state.multimedia.isCreating,
    apiError: state.multimedia.APIError || state.multimedia.code,
    isfetching: state.multimedia.isFetching,
    multimedia: state.multimedia.items.map(m => ({
      ...m,
      tipo: MULTIMEDIA_TYPES[m.tipo],
    })),
  }
}

const mapActionsToProps = {
  fetchMultimedia: multimediaActions.fetchResources,
  createMultimedia: multimediaActions.createResource,
}

export default connect(mapStateToProps, mapActionsToProps)(Multimedia)
