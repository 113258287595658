import { FetchWrapper } from './FetchWrapper'

const adminAPIMixin = (Base: typeof FetchWrapper) =>
  class extends Base {
    // #region GET

    fetchAlertas = async () => {
      return this.get({
        endpoint: '/alertas',
      })
    }

    fetchAlerta = async parms => {
      if (parms.slug) {
        return this.get({
          endpoint: '/alerta/' + parms.slug,
        })
      }
    }

    fetchCaso = async parms => {
      if (parms.slug) {
        return this.get({
          endpoint: '/caso/' + parms.slug,
        })
      }
    }

    fetchCasos = async parms => {
      return this.get({
        endpoint: '/casos',
      })
    }

    fetchTiposDeCasos = async () => {
      return this.get({
        endpoint: '/tipos-de-caso',
      })
    }

    fetchDefensoras = async () => {
      return this.get({
        endpoint: '/defensores',
      })
    }

    fetchDefensora = async parms => {
      return this.get({
        endpoint: '/defensor/' + parms.slug,
      })
    }

    fetchMultimedia = async () => {
      return this.get({ endpoint: '/multimedia' })
    }

    fetchMultimediaItem = async parms => {
      return this.get({ endpoint: '/multimedia/' + parms.slug })
    }
    // #region POST

    createAlerta = async body => {
      return this.post({
        endpoint: '/alerta',
        body,
      })
    }

    createTiposDeCaso = async (nombre: string) => {
      return this.post({
        endpoint: '/tipo-de-caso',
        body: { nombre },
      })
    }

    createCaso = async body => {
      return this.post({
        endpoint: '/caso',
        body,
      })
    }

    createDefensora = async body => {
      return this.post({
        endpoint: '/defensores',
        body,
      })
    }

    createMultimedia = async body => {
      return this.post({
        endpoint: '/multimedia',
        body,
      })
    }
    // #region DELETE

    deleteTiposDeCaso = async (slug: string) => {
      return this.del({
        endpoint: '/tipo-de-caso/' + slug,
        itemToDelete: slug,
      })
    }

    deleteCaso = async parms => {
      return this.del({
        endpoint: `/caso/${parms.paretSlug}/${parms.slug}`,
        itemToDelete: parms.slug,
      })
    }

    deleteDefensora = async (slug: string) => {
      return this.del({
        endpoint: `/defensor/${slug}`,
        itemToDelete: slug,
      })
    }

    deleteMultimediaItem = async (slug: string) => {
      return this.del({
        endpoint: `/multimedia/${slug}`,
        itemToDelete: slug,
      })
    }

    deleteAlerta = async (slug: string) => {
      return this.del({
        endpoint: `/alerta/${slug}`,
        itemToDelete: slug,
      })
    }

    // PUT
    updateCaso = async parms => {
      if (parms.paretSlug && parms.slug) {
        return this.put({
          endpoint: `/caso/${parms.paretSlug}/${parms.slug}`,
          body: parms.body,
        })
      }
    }

    updateDefensora = async parms => {
      if (parms.slug) {
        return this.put({
          endpoint: `/defensor/${parms.slug}`,
          body: parms.body,
        })
      }
    }

    updateMultimediaItem = async parms => {
      if (parms.slug) {
        return this.put({
          endpoint: `/multimedia/${parms.slug}`,
          body: parms.body,
        })
      }
    }

    updateAlerta = async parms => {
      if (parms.slug) {
        return this.put({
          endpoint: `/alerta/${parms.slug}`,
          body: parms.body,
        })
      }
    }
  }

export default adminAPIMixin
