import React, { Component } from 'react'

import { RouterHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { Section, success, error } from '../Components'
import { defensorasActions, casosActions, AppState } from '../state'
import { URLS, TIPO_FAMILIARES } from '../constants'
import { getFormValues } from 'redux-form'

type OwnProps = { history: RouterHistory }

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

type IValForm = {
  nombre: string
  descripcion: {
    time: number
    version: string
    blocks: any[]
  }
  case: { value: string }

  publicar: boolean
  tipoFamiliar: {
    value: string
    label: string
  }
  audio: string
  imagen: string
}

const COLUMNS = [
  { id: 'publicado', label: 'Publicado' },
  { id: 'nombre', label: 'Nombre' },
  { id: 'victima', label: 'Victima' },
  { id: 'created', label: 'Fecha de Publicación', type: 'date' },
  { id: 'editar', label: 'Editar' },
]

class Moms extends Component<Props> {
  state = {
    closeModal: false,
  }
  componentDidMount() {
    const { casos } = this.props
    this.props.fetchDefensoras()

    if (!casos.length) {
      this.props.fetchCasos()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isCreatingDefensora, apiError, showHint } = this.props
    const { closeModal } = this.state

    if (showHint && !prevProps.showHint) {
      error('El link del audio debe ser se Soundcloud')
    }

    if (isCreatingDefensora && !prevProps.isCreatingDefensora) {
      if (apiError) {
        this.setState({ closeModal: true }, () =>
          error('¡¡Upps!!!, Ha habido un error al crear este Defensora.')
        )
      }
      this.props.fetchDefensoras()
      this.setState({ closeModal: true }, () =>
        success('Defensora creado con éxito')
      )
    }

    if (closeModal && !prevState.closeModal) {
      this.setState({ closeModal: false })
    }
  }

  create = () => {
    const { formValues, createDefensora } = this.props

    const defensor = {
      foto: formValues.imagen,
      casos: [formValues.case.value],
      tipo_familiar: formValues.tipoFamiliar.label,
      nombre: formValues.nombre,
      descripcion: formValues.descripcion,
      publicado: formValues.publicar,
      audio: [formValues.audio],
    }

    createDefensora(defensor)
  }
  render() {
    const { defensoras, casos, disableButton, isfetching } = this.props

    const { closeModal } = this.state

    return (
      <Section
        name="Defensoras"
        itemURL={URLS.MOM}
        item="Defensora"
        formFields={[
          { type: 'image', name: 'Imagen Principal', fieldName: 'imagen' },
          { type: 'text', name: 'Nombre', fieldName: 'nombre' },
          {
            type: 'select',
            name: 'Tipo de Familiar',
            fieldName: 'tipoFamiliar',
            options: TIPO_FAMILIARES,
          },
          {
            type: 'select',
            name: 'Caso relacionado',
            fieldName: 'case',
            options: casos
              .filter(c => !c.defensores_id)
              .map(t => ({
                value: t._id,
                label: t.nombre,
              })),
          },
          { type: 'editor', name: 'Historia', fieldName: 'descripcion' },
          { type: 'link', name: 'Audio', fieldName: 'audio' },
        ]}
        routeHistory={this.props.history}
        columns={COLUMNS}
        tableData={defensoras}
        createItem={this.create}
        disableButton={disableButton}
        closeModal={closeModal}
        isFetching={isfetching}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('DefensorasForm')(state) as IValForm
  const showHint =
    formValues && formValues.audio
      ? formValues.audio && formValues.audio.includes('soundcloud')
      : true

  const disableButton =
    formValues && formValues.nombre && formValues.case && showHint

  return {
    showHint: !showHint,
    casos: state.casos.items.filter(c => !c.defensores_id),
    defensoras: state.defensoras.items,
    isCreatingDefensora: state.defensoras.isCreating,
    isfetching: state.defensoras.isFetching,
    apiError: state.defensoras.APIError || state.defensoras.code,
    disableButton: !disableButton,
    formValues,
  }
}
const mapActionsToProps = {
  fetchCasos: casosActions.fetchResources,
  fetchDefensoras: defensorasActions.fetchResources,
  createDefensora: defensorasActions.createResource,
}

export default connect(mapStateToProps, mapActionsToProps)(Moms)
