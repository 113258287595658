import getConfig from '../../../config'

import Cookies from 'universal-cookie'

const cookies = new Cookies()

const refreshToken = cookies.get('refresh_token')

const urlRoot = getConfig().API_URL

const getUrl = (type: string) => {
  switch (type) {
    case 'CasoFormEdit':
    case 'CasosForm':
      return urlRoot + '/upload-image-caso'
    case 'DefensoraFormEdit':
    case 'DefensorasForm':
      return urlRoot + '/upload-image-defensora'
    case 'MultimediaFormEdit':
    case 'MultimediaForm':
      return urlRoot + '/upload-image-multimedia'
    default:
      return ''
  }
}

const postImage = async (file, type, reduxResponce) => {
  var formData = new FormData()
  formData.append('upload-image', file)

  const urlRequest = getUrl(type)

  if (!urlRequest) return

  fetch(urlRequest, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  }).then(async response => {
    reduxResponce(response.json())
  })
}

export default postImage
