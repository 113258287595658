import React, { PureComponent } from 'react'

import { EditSection, error, success } from '../Components'
import { connect } from 'react-redux'

import { RouterHistory, Match } from 'react-router-dom'
import { URLS, TIPO_FAMILIARES } from '../constants'
import {
  AppState,
  casosActions,
  defensoraActions,
  defensorasActions,
} from '../state'
import { getFormValues } from 'redux-form'

import 'moment/locale/es'
import 'moment-timezone'

type OwnProps = {
  history: RouterHistory
  match: Match
}

type IValForm = {
  defensoraNombre: string
  tipoFamiliar: {
    value: string
    label: string
  }
  case: {
    value: number
    label: string
  }
  historia: any
  audio: string
  publicar: boolean
  imagen: string
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

class Mom extends PureComponent<Props> {
  componentDidMount() {
    const { casos, slug } = this.props

    if (!casos.length) {
      this.props.fetchCasos()
    }

    if (slug) {
      this.props.fetchDefensora({ slug })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiError, isUpdating, history, isDeleting, showHint } = this.props

    if (showHint && !prevProps.showHint) {
      error('El link del audio debe ser se Soundcloud')
    }

    if (!isUpdating && prevProps.isUpdating) {
      if (apiError) {
        error('¡¡Upps!!!, Ha habido un error al actualizar correctamente.')
      } else {
        this.props.fetchDefensoras()
        success('Elemento editado correctamente.')
        history.push(URLS.MOMS)
      }
    }

    if (!isDeleting && prevProps.isDeleting) {
      this.props.fetchDefensoras()
      success('Defensora borrado correctamente.')
      history.push(URLS.MOMS)
    }
  }

  editItem = () => {
    const { updateDefensora, formValues, slug } = this.props

    const editedDefensora = {
      casos: [formValues.case.value],
      tipo_familiar: formValues.tipoFamiliar.label,
      nombre: formValues.defensoraNombre, // Requerido
      descripcion: formValues.historia,
      publicado: formValues.publicar,
      audio: [formValues.audio],
      foto: formValues.imagen,
    }

    updateDefensora({
      slug,
      body: editedDefensora,
    })
  }

  goToPrevious = () => {
    const { history } = this.props

    if (history.location.key) {
      history.goBack()
    } else {
      history.push(URLS.MOMS)
    }
  }

  delete = () => {
    const { deleteDefensora, slug } = this.props

    deleteDefensora(slug)
  }

  render() {
    const { initialValues, casos, disableButton, defensora } = this.props

    return (
      <div className="shape-content">
        <div className="shape-row">
          <div className="shape-col-auto">
            <EditSection
              initialValues={initialValues}
              routeHistory={this.props.history}
              name="Defensora"
              cancelForm={() => this.goToPrevious()}
              editItem={this.editItem}
              disableButton={!!disableButton}
              deleteItem={this.delete}
              formFields={[
                {
                  type: 'image',
                  name: 'Imagen Principal',
                  fieldName: 'imagen',
                },
                {
                  type: 'text',
                  name: 'Nombre',
                  fieldName: 'defensoraNombre',
                },
                {
                  type: 'select',
                  name: 'Tipo de Familiar',
                  fieldName: 'tipoFamiliar',
                  options: TIPO_FAMILIARES,
                },
                {
                  type: 'select',
                  name: 'Caso relacionado',
                  fieldName: 'case',
                  disabled: true,
                  options:
                    casos &&
                    casos
                      .filter(
                        c =>
                          (c.defensores_id &&
                            defensora &&
                            defensora.casos_id &&
                            c.defensores_id ===
                              defensora.casos_id[0].defensores_id) ||
                          !c.defensores_id
                      )
                      .map(t => ({
                        value: t._id,
                        label: t.nombre,
                      })),
                },
                {
                  type: 'editor',
                  name: 'Historia',
                  fieldName: 'historia',
                },
                { type: 'link', name: 'Audio', fieldName: 'audio' },
                {
                  type: 'delete',
                  name: 'Borrar Defensora',
                  fieldName: 'delete',
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { match } = ownProps

  const slug = match.params.slug

  const formValues = getFormValues('DefensoraFormEdit')(state) as IValForm
  const showHint =
    formValues && formValues.audio
      ? formValues.audio && formValues.audio.includes('soundcloud')
      : true

  const disableButton =
    formValues &&
    formValues.defensoraNombre &&
    formValues.tipoFamiliar &&
    formValues.case &&
    showHint

  const defensora = state.defensora.items && state.defensora.items[0]
  const casos = state.casos.items

  const relatedCaso =
    casos && !!casos.length && defensora && defensora.casos_id[0]
      ? casos.find(c => c.slug === defensora.casos_id[0].slug)
      : null

  const tipoFamiliar = defensora
    ? TIPO_FAMILIARES.find(tipo => tipo.label === defensora.tipo_familiar)
    : TIPO_FAMILIARES[0]

  return {
    slug,
    defensora,
    showHint: !showHint,
    defensoras: state.casos.items.filter(c => !c.defensores_id),
    isUpdating: state.defensora.isUpdating,
    isDeleting: state.defensora.isDeleting,
    apiError: state.defensoras.APIError,
    casos: state.casos.items,
    formValues,
    initialValues: defensora
      ? {
          defensoraNombre: defensora.nombre,
          tipoFamiliar,
          case: relatedCaso
            ? {
                value: relatedCaso._id,
                label: relatedCaso.nombre,
              }
            : null,
          historia: defensora.descripcion,
          audio: defensora.audio ? defensora.audio[0] : '',
          publicar: defensora.publicado,
          imagen: defensora.foto,
        }
      : {},
    disableButton: !disableButton,
  }
}
const mapActionsToProps = {
  fetchCasos: casosActions.fetchResources,

  fetchDefensora: defensoraActions.fetchResources,
  fetchDefensoras: defensorasActions.fetchResources,
  deleteDefensora: defensoraActions.deleteResource,
  updateDefensora: defensoraActions.updateResource,
}

export default connect(mapStateToProps, mapActionsToProps)(Mom)
