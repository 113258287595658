import { WrappedFieldProps } from 'redux-form'

import React from 'react'
import './index.css'

type Props = {
  label: string
  hint?: string
} & React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

const Textarea = (props: Props) => {
  const {
    label,
    hint,
    meta: { error, touched },
    input,
  } = props

  return (
    <div className={`form-group ${touched && error ? ' error' : ''}`}>
      {label && <label className="input-name">{label}</label>}
      {hint && <div className="hint">{hint}</div>}
      <textarea {...input} placeholder={label} className="input" />
      {touched && error && <span className="error-feedback">{error}</span>}
    </div>
  )
}

export default Textarea
