import React, { PureComponent, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PAGES_IDS, URLS } from '../../constants'
import { logoLg, logoSm } from '../../Images'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Cookies from 'universal-cookie'

import getConfig from '../../config'
import { Auth } from '../../auth'

const cookies = new Cookies()

const auth = new Auth(getConfig())

type Entry = {
  id: string
  label: string
  path: string
  disabled?: boolean
  notification?: boolean
  icon: IconProp
}

type Props = {
  linkComponent: any
  current: string
  entries: Array<Entry>
  status: (status: string) => void
  history: any
}

type State = {
  collapsed: boolean
}

class Sidebar extends PureComponent<Props, State> {
  state = {
    collapsed: localStorage.getItem('sidebar') === 'collapsed',
  }

  componentDidMount() {
    const { current } = this.props

    if (current !== PAGES_IDS.LOGIN.name.toLowerCase()) {
      const status = localStorage.getItem('sidebar') || 'open'
      this.props.status(status)
    } else {
      this.props.status('login')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { collapsed } = this.state

    if (prevState.collapsed !== collapsed) {
      const status = collapsed ? 'collapsed' : 'open'
      this.props.status(status)
    }
  }

  toggleSidebar = () => {
    this.setState(
      prevState => ({ collapsed: !prevState.collapsed }),
      () => {
        localStorage.setItem(
          'sidebar',
          this.state.collapsed ? 'collapsed' : 'open'
        )
      }
    )
  }

  logout = () => {
    auth.logout()
    cookies.remove('access_token')
    cookies.remove('refresh_token')

    this.props.history.push(URLS.LOGIN)
  }

  render() {
    const { linkComponent: Link, current, entries } = this.props
    const { collapsed } = this.state

    const sidebarClass = collapsed ? 'collapsed' : 'open'
    const sidebarArrowIcon = collapsed
      ? 'angle-double-right'
      : 'angle-double-left'

    const logo = sidebarClass === 'collapsed' ? logoSm : logoLg

    return (
      <Fragment>
        {current !== PAGES_IDS.LOGIN.name.toLowerCase() ? (
          <div className={`shape-sidebar ${sidebarClass}`}>
            <Link to={URLS.CASES} className="logo-container">
              <img src={logo} alt="nvavj-logo" />
            </Link>

            {entries.map(i => {
              const active = current === i.id ? 'active ' : ''
              const disabled = i.disabled ? 'disabled' : ''
              return (
                <Link
                  key={i.id}
                  to={i.path}
                  className={`${active} ${disabled} shape-sidebar-page`}
                >
                  {i.notification && (
                    <div className="shape-sidebar-notification" />
                  )}

                  <FontAwesomeIcon icon={i.icon} fixedWidth />
                  {!collapsed && <p>{i.label}</p>}
                </Link>
              )
            })}

            <div className="shape-sidebar-arrow" onClick={this.toggleSidebar}>
              <div className="shape-sidebar-arrow-container">
                <span>
                  <FontAwesomeIcon icon={sidebarArrowIcon} />
                </span>
                {!collapsed && <p>Cerrar</p>}
              </div>
            </div>
            <div className="log-out" onClick={() => this.logout()}>
              Logout
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default Sidebar
