import React from 'react'
import { Link, Route } from 'react-router-dom'
import Sidebar from './Sidebar'
import { URLS } from '../../constants'

import './index.css'
import { invertEditUrl } from '../../utils'

type Props = {
  status: (status: string) => void
}

const currentPath = (pathname: string) => {
  switch (invertEditUrl(pathname, '')) {
    case URLS.CASES:
      return URLS.CASES.replace('/', '')
    case URLS.MOMS:
      return URLS.MOMS.replace('/', '')
    case URLS.MULTIMEDIA:
      return URLS.MULTIMEDIA.replace('/', '')
    case URLS.FLASH_NEWS:
      return URLS.FLASH_NEWS.replace('/', '')
    case URLS.CONTACTS:
      return URLS.CONTACTS.replace('/', '')
    default:
      return 'login'
  }
}

const SidebarWrapper = (props: Props) => {
  const { status } = props

  return (
    <Route
      render={props => (
        <Sidebar
          linkComponent={Link}
          entries={[
            { id: 'casos', label: 'Casos', path: URLS.CASES, icon: 'female' },
            {
              id: 'defensoras',
              label: 'Defensoras',
              path: URLS.MOMS,
              icon: 'heart',
            },
            {
              id: 'multimedia',
              label: 'Multimedia',
              path: URLS.MULTIMEDIA,
              icon: 'archive',
            },
            {
              id: 'alertas',
              label: 'Alertas',
              path: URLS.FLASH_NEWS,
              icon: 'bolt',
            },
          ]}
          current={currentPath(props.location.pathname)}
          status={status}
          history={props.history}
        />
      )}
    />
  )
}

export default SidebarWrapper
