import React, { Component } from 'react'
import { getFormValues } from 'redux-form'
import { RouterHistory } from 'react-router-dom'

import { Section, success, error } from '../Components'
import { connect } from 'react-redux'
import {
  AppState,
  casosActions,
  tiposDeCasosActions,
  defensorasActions,
} from '../state'
import { LOCATIONS, URLS } from '../constants'

type OwnProps = { history: RouterHistory }

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

const COLUMNS = [
  { id: 'publicado', label: 'Publicado' },
  { id: 'nombre', label: 'Nombre' },
  { id: 'tipoCaso', label: 'Tipo de Caso' },
  { id: 'created', label: 'Fecha de Publicación', type: 'date' },
  { id: 'editar', label: 'Editar' },
]

type IValForm = {
  victimaNombre: string
  tipo: {
    value: string
    label: string
  }
  fechaDeNacimiento: moment.Moment
  historia: {
    time: number
    version: string
    blocks: any[]
  }
  intro: string
  description: string
  lugarDeNacimiento: { value: string }
  fechaDeFeminicidio: moment.Moment
  lugarDeFeminicidio: { value: string }
  publicar: boolean
  imagen: string
  material: {
    fecha: moment.Moment
    origen: string
    titulo: string
    url: string
  }[]
}

type State = {
  closeModal: boolean
}
class Cases extends Component<Props, State> {
  state = {
    closeModal: false,
  }
  componentDidMount() {
    const { defensoras } = this.props

    if (!defensoras.length) {
      this.props.fetchDefensoras()
    }
    this.props.fetchCasos()

    this.props.fetchTiposDeCasos()
  }

  create = () => {
    const { formValues, createCaso } = this.props

    const caso = formValues &&
      formValues.tipo &&
      formValues.lugarDeNacimiento && {
        foto: formValues.imagen,
        tipoCaso: formValues.tipo.label,
        titulo: formValues.victimaNombre,
        nombre: formValues.victimaNombre,
        historia: formValues.historia,
        intro: formValues.intro,
        descripcion: formValues.description,
        feminicidio: {
          lugar: formValues.lugarDeFeminicidio.value,
          fecha: formValues.fechaDeFeminicidio.utc().format(),
        },
        victima: {
          nombre: formValues.victimaNombre,
          fecha_nacimiento: formValues.fechaDeNacimiento.utc().format(),
          lugar_nacimiento: formValues.lugarDeNacimiento.value,
        },
        publicado: formValues.publicar,
        material: formValues.material
          ? formValues.material.map(m => ({
              origen: `${m.fecha.format('DD MMMM YYYY')}/ ${m.origen}`,
              titulo: m.titulo,
              url: m.url,
            }))
          : null,
      }

    createCaso(caso)
  }

  componentDidUpdate(prevProps, prevState) {
    const { isCreatingCaso, apiError } = this.props
    const { closeModal } = this.state

    if (isCreatingCaso && !prevProps.isCreatingCaso) {
      if (apiError) {
        this.setState({ closeModal: true }, () =>
          error('¡¡Upps!!!, Ha habido un error al crear este caso.')
        )
      }
      this.props.fetchCasos()
      this.setState({ closeModal: true }, () =>
        success('Caso creado con éxito')
      )
    }

    if (closeModal && !prevState.closeModal) {
      this.setState({ closeModal: false })
    }
  }

  render() {
    const { casos, tiposDeCasos, disableButton, isFetching } = this.props
    const { closeModal } = this.state
    return (
      <>
        <Section
          name="Casos"
          itemURL={URLS.CASE}
          routeHistory={this.props.history}
          formFields={[
            { type: 'image', name: 'Imagen Principal', fieldName: 'imagen' },
            {
              type: 'select',
              name: 'Tipo de Caso',
              fieldName: 'tipo',
              options: tiposDeCasos.map(t => ({
                value: t.slug,
                label: t.nombre,
              })),
              newCaseType: 'Tipo de Caso',
            },
            {
              type: 'text',
              name: 'Nombre de la Víctima',
              fieldName: 'victimaNombre',
            },

            {
              type: 'select',
              name: 'Lugar de Nacimiento',
              fieldName: 'lugarDeNacimiento',
              options: LOCATIONS,
            },
            {
              type: 'date',
              name: 'Fecha de Nacimiento',
              fieldName: 'fechaDeNacimiento',
            },
            { type: 'textarea', name: 'Intro', fieldName: 'intro' },
            {
              type: 'textarea',
              name: 'Descripcion',
              fieldName: 'description',
              hint:
                'Para subrayar agrupa el texto  siguiente forma "#1# Tu texto #2#"',
              view: true,
            },
            {
              type: 'select',
              name: 'Lugar de Feminicidio',
              fieldName: 'lugarDeFeminicidio',
              options: LOCATIONS,
            },
            {
              type: 'date',
              name: 'Fecha de Feminicidio',
              fieldName: 'fechaDeFeminicidio',
            },
            { type: 'editor', name: 'Historia', fieldName: 'historia' },
            {
              type: 'linkArray',
              name: 'Materiale Hemerográfico',
              fieldName: 'material',
            },
          ]}
          columns={COLUMNS}
          tableData={casos}
          createItem={this.create}
          disableButton={disableButton}
          closeModal={closeModal}
          isFetching={isFetching}
        />
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('CasosForm')(state) as IValForm
  const disableButton =
    formValues &&
    formValues.tipo &&
    formValues.lugarDeNacimiento &&
    formValues.lugarDeFeminicidio &&
    formValues.fechaDeNacimiento &&
    formValues.fechaDeFeminicidio &&
    formValues.tipo.label &&
    formValues.victimaNombre

  return {
    casos: state.casos.items,
    defensoras: state.defensoras.items,
    tiposDeCasos: state.tiposDeCasos.items,
    isCreatingtipoCaso: state.tiposDeCasos.isCreating,
    isCreatingCaso: state.casos.isCreating,
    isFetching: state.casos.isFetching,
    hasFetched: state.casos.hasFetched,
    apiError: state.casos.code || state.casos.APIError,
    formValues,
    disableButton: !disableButton,
  }
}
const mapActionsToProps = {
  fetchCasos: casosActions.fetchResources,
  fetchTiposDeCasos: tiposDeCasosActions.fetchResources,
  fetchDefensoras: defensorasActions.fetchResources,
  createCaso: casosActions.createResource,
}

export default connect(mapStateToProps, mapActionsToProps)(Cases)
