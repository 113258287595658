import React, { Component } from 'react'

import ReactPlaceholder from 'react-placeholder'
import 'moment/locale/es'

import './index.css'

type Props = {
  columns: Array<{
    id: string
    label?: string
    date?: moment.Moment
    type?: string
  }>
  render: () => any
  ready?: boolean
  placeholderRows?: number
}

export default class SortableTable extends Component<Props> {
  static defaultProps = {
    ready: true,
  }

  render() {
    const { columns, render, ready, placeholderRows } = this.props

    return (
      <table className="shape-table shape-table-hover">
        <thead>
          <tr>
            {columns.map(({ id, label }) => (
              <th key={id} id={id}>
                <span>{label}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ready
            ? render()
            : Array.from({ length: placeholderRows || 3 }, (v, k) => k).map(
                x => (
                  <tr key={x}>
                    {columns.map(({ id }) => (
                      <td key={id}>
                        <ReactPlaceholder type="textRow" ready={false}>
                          <></>
                        </ReactPlaceholder>
                      </td>
                    ))}
                  </tr>
                )
              )}
        </tbody>
      </table>
    )
  }
}
