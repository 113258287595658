import React, { Component } from 'react'
import { RouterHistory, Match } from 'react-router-dom'
import { connect } from 'react-redux'

import { EditSection, error, success } from '../Components'
import { AppState, multimediaItemActions, multimediaActions } from '../state'
import { getFormValues } from 'redux-form'
import { URLS } from '../constants'

type IValForm = {
  imagen: string
  titulo: string
  tipo: {
    value: string
    label: string
  }
  serie: string
  descripcion: string
  link: string
}

type OwnProps = {
  history: RouterHistory
  match: Match
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps
class MultimediaItem extends Component<Props> {
  componentDidMount() {
    const { slug } = this.props

    if (slug) {
      this.props.fetchMultimedia({ slug })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiError, isUpdating, history, isDeleting, showHint } = this.props

    if (showHint !== prevProps.showHint && showHint) {
      error('El link del video debe ser de youtube o vimeo')
    }

    if (!isUpdating && prevProps.isUpdating) {
      if (apiError) {
        error('¡¡Upps!!!, Ha habido un error al actualizar correctamente.')
      } else {
        this.props.fetchAllMultimedia()
        success('Elemento editado correctamente.')
        history.push(URLS.MULTIMEDIA)
      }
    }

    if (!isDeleting && prevProps.isDeleting) {
      this.props.fetchAllMultimedia()
      success('Elemento borrado correctamente.')
      history.push(URLS.MULTIMEDIA)
    }
  }

  editItem = () => {
    const { updateMultimediaItem, formValues, slug } = this.props

    const multimediaEdit = formValues && {
      titulo: formValues.titulo,
      imagen: formValues.imagen,
      tipo: formValues.tipo.value,
      serie: formValues.serie,
      descripcion: formValues.descripcion,
      link: formValues.link,
    }

    updateMultimediaItem({
      slug,
      body: multimediaEdit,
    })
  }

  goToPrevious = () => {
    const { history } = this.props

    if (history.location.key) {
      history.goBack()
    } else {
      history.push(URLS.CASES)
    }
  }

  delete = () => {
    const { deleteMultimediaItem, slug } = this.props

    deleteMultimediaItem(slug)
  }

  render() {
    const { disableButton, initialValues, showLink } = this.props

    const link = showLink
      ? { type: 'text', name: 'Link', fieldName: 'link' }
      : {}
    const description = !showLink
      ? { type: 'textarea', name: 'Descripcion', fieldName: 'descripcion' }
      : {}

    return (
      <div className="shape-content">
        <div className="shape-row">
          <div className="shape-col-auto">
            <EditSection
              initialValues={initialValues}
              routeHistory={this.props.history}
              name="Multimedia"
              cancelForm={() => this.goToPrevious()}
              editItem={this.editItem}
              disableButton={!!disableButton}
              deleteItem={this.delete}
              formFields={[
                {
                  type: 'image',
                  name: 'Imagen',
                  fieldName: 'imagen',
                },
                { type: 'text', name: 'Serie', fieldName: 'serie' },
                { type: 'text', name: 'Titulo', fieldName: 'titulo' },
                {
                  type: 'select',
                  name: 'Tipo',
                  fieldName: 'tipo',
                  options: [
                    {
                      value: 'video',
                      label: 'Video',
                    },
                    {
                      value: 'postal',
                      label: 'Postal',
                    },
                    {
                      value: 'infografia',
                      label: 'Infografía',
                    },
                    {
                      value: 'changeorg',
                      label: 'Change.org',
                    },
                  ],
                },

                description,
                link,
                {
                  type: 'delete',
                  name: 'Borrar Elemento de Multimedia',
                  fieldName: 'delete',
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const formValues = getFormValues('MultimediaFormEdit')(state) as IValForm
  const { match } = ownProps

  const slug = match.params.slug

  const multimedia = state.multimediaItem.items[0]

  const showLink =
    formValues && formValues.tipo && formValues.tipo.value === 'video'

  const showHint =
    formValues && formValues.link
      ? (formValues.link && formValues.link.includes('youtube')) ||
        (formValues.link && formValues.link.includes('vimeo'))
      : true

  const disableIfLinkIsWrong = showLink
    ? formValues && formValues.link && showHint
    : !showLink

  const disableButton =
    formValues && formValues.titulo && formValues.tipo && disableIfLinkIsWrong

  return {
    slug,
    multimedia,
    formValues,
    showHint: !showHint,
    apiError: state.multimediaItem.APIError,
    isUpdating: state.multimediaItem.isUpdating,
    isDeleting: state.multimediaItem.isDeleting,
    showLink,
    disableButton: !disableButton,
    initialValues: multimedia
      ? {
          titulo: multimedia.titulo,
          imagen: multimedia.imagen,
          tipo: {
            value: multimedia.tipo,
          },
          serie: multimedia.serie,
          descripcion: multimedia.descripcion || '',
          link: multimedia.link,
        }
      : {},
  }
}
const mapActionsToProps = {
  fetchAllMultimedia: multimediaActions.fetchResources,
  fetchMultimedia: multimediaItemActions.fetchResources,
  updateMultimediaItem: multimediaItemActions.updateResource,
  deleteMultimediaItem: multimediaItemActions.deleteResource,
}
export default connect(mapStateToProps, mapActionsToProps)(MultimediaItem)
