import { FormField } from '../../types'

import React, { PureComponent, Fragment } from 'react'
import FormNewItem from '../FormCreateOrEdit'
import { Modal, Table } from '../index'
import Cookies from 'universal-cookie'
import { RouterHistory, Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'
import 'moment-timezone'

import getConfig from '../../config'
import { Auth } from '../../auth'

import './index.css'
import { URLS } from '../../constants'
import { url } from '../../utils'

const auth = new Auth(getConfig())

const cookies = new Cookies()

type Props = {
  isFetching?: boolean
  disableButton?: boolean
  createItem?: () => void
  closeModal?: boolean
  name: string
  item?: string
  itemURL?: string
  formFields?: FormField[]
  routeHistory: RouterHistory
  tableData?: {
    nombre?: string
    ubicacion?: string
    created_at?: string
    publicado?: boolean
    slug?: string
    tipoCaso_id?: {
      _id: string
      nombre: string
    }
  }[]
  columns?: {
    label: string
    id: string
    type?: string
  }[]
}

type State = {
  modalStatus: boolean
  isAuthed: boolean
}

class Section extends PureComponent<Props, State> {
  state = {
    modalStatus: !!this.props.closeModal,
    isAuthed: false,
  }

  componentDidMount() {
    const refreshToken = cookies.get('refresh_token')

    auth
      .authenticate(refreshToken)
      .then(token =>
        cookies.set('refresh_token', token.token, {
          path: '/',
        })
      )
      .catch(error => this.props.routeHistory.push(URLS.LOGIN))
  }

  componentDidUpdate(prevProps, prevState) {
    const { closeModal } = this.props

    if (closeModal !== prevProps.closeModal) {
      this.setState({ modalStatus: !!closeModal })
    }
  }

  render() {
    const {
      name,
      itemURL,
      formFields,
      tableData,
      columns,
      createItem,
      disableButton,
      isFetching,
    } = this.props

    const { modalStatus } = this.state

    return (
      <div className={`${name.toLowerCase()} shape-content form`}>
        <div className="shape-row">
          <div className="shape-col-auto">
            <div className="section-title">{name}</div>

            {tableData && !!tableData.length && columns ? (
              <>
                <Table
                  ready={!isFetching}
                  columns={columns}
                  placeholderRows={14}
                  render={() =>
                    tableData.map((row, key) => (
                      <tr key={key}>
                        {columns.map((column, k) => (
                          <Fragment key={k}>
                            {column.id === 'publicado' ? (
                              <td className="lg">
                                {row[column.id] ? (
                                  <div className="ligth publish">Publicado</div>
                                ) : (
                                  <div className="ligth  noPublish">
                                    No publicado
                                  </div>
                                )}
                              </td>
                            ) : column.id === 'editar' ? (
                              <td className="lg">
                                <Link
                                  to={url(itemURL, row.slug)}
                                  className="edit btn"
                                >
                                  Click para Editar
                                </Link>
                              </td>
                            ) : column.type === 'date' ? (
                              <td>
                                {row[column.id] &&
                                moment.isMoment(row[column.id])
                                  ? row[column.id]
                                      .locale('es')
                                      .tz('America/Mexico_City')
                                      .format('LL')
                                  : '-'}
                              </td>
                            ) : column.id === 'borrar' ? (
                              <td>
                                <div className="borrar btn">Borrar</div>
                              </td>
                            ) : (
                              <td>{row[column.id]}</td>
                            )}
                          </Fragment>
                        ))}
                      </tr>
                    ))
                  }
                ></Table>
              </>
            ) : (
              <div>No existen elementos para mostrar</div>
            )}
            {itemURL ? (
              <Modal
                status={(status: boolean) =>
                  this.setState({ modalStatus: status })
                }
                closeModal={!!modalStatus}
              >
                <FormNewItem
                  name={`${name}Form`}
                  formFields={formFields}
                  cancelForm={(status: boolean) =>
                    this.setState({ modalStatus: status })
                  }
                  createItem={createItem}
                  disableButton={disableButton}
                />
              </Modal>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default Section
