import { WrappedFieldProps } from 'redux-form'

import React, { PureComponent } from 'react'
import { SingleDatePicker } from 'react-dates'

type OwnProps = {
  label: string
} & React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

type Props = OwnProps

type State = {
  focused: boolean
}

export default class DatePicker extends PureComponent<Props, State> {
  state = {
    focused: false,
  }

  handleFocusChange = ({ focused }: { focused: boolean }) => {
    this.setState({ focused })
  }

  render() {
    const {
      label,
      meta: { error, touched },
      input: { value, onChange, onBlur },
      ...rest
    } = this.props

    return (
      <div className="form-group">
        {label && <label className="input-name">{label}</label>}
        <SingleDatePicker
          date={value || null}
          onDateChange={(...args) => {
            // @ts-ignore
            onChange(...args)
            // @ts-ignore
            onBlur(...args) // toggle 'touched: true'
          }}
          focused={this.state.focused}
          onFocusChange={this.handleFocusChange}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={() => false}
          noBorder={true}
          numberOfMonths={1}
          displayFormat={() => 'DD MMMM YYYY'}
          {...rest}
        />
        {touched && error && <span className="error">{error}</span>}
      </div>
    )
  }
}
