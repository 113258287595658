import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { lowerFirst } from 'lodash'
import { renderInput } from '../index'
import Button from '../../Button'
import { AppState } from '../../../state'

type OwnProps = {
  label: string
  name: string
  formName: string
  editRow: (value: string) => void
  deleteRow?: (slug: string) => void
  slug?: string
}
type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & OwnProps

const RowField = (props: Props) => {
  const { name, value, editRow, isCreating, deleteRow, slug } = props
  const color = !!deleteRow ? 'purple' : 'dark-purple'
  return (
    <tr>
      <td>
        <Field color="primary" name={name} component={renderInput} />
      </td>
      <td>
        <Button
          text={`${!!deleteRow ? 'Guardar Cambios' : 'Crear Tipo de Caso'}`}
          size="xs"
          disabled={!value}
          color={color}
          onClick={() => editRow(value)}
          loading={isCreating}
        />
      </td>
      <td>
        <Button
          text="Borrar"
          size="xs"
          color="orange"
          disabled={!deleteRow || !slug}
          onClick={() => (deleteRow && slug ? deleteRow(slug) : null)}
        />
      </td>
    </tr>
  )
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { formName, name, label } = ownProps

  const selector = formValueSelector(formName)
  const listName = lowerFirst(label.replace(/ /g, ''))
  const resourse = state[listName]

  return {
    value: selector(state, name),
    isCreating: resourse ? resourse.isCreating : false,
  }
}

export default connect(mapStateToProps)(RowField)
