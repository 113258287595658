import React, { PureComponent } from 'react'
import { FormField } from '../../types'

import ReactPlaceholder from 'react-placeholder'
import Cookies from 'universal-cookie'
import { RouterHistory } from 'react-router-dom'

import FormNewItem from '../FormCreateOrEdit'

import { URLS } from '../../constants'
import getConfig from '../../config'
import { Auth } from '../../auth'
import './index.css'

const auth = new Auth(getConfig())

const cookies = new Cookies()

type Props = {
  initialValues: any
  disableButton?: boolean
  editItem?: () => void
  cancelForm: () => void
  deleteItem?: () => void
  name: string
  routeHistory: RouterHistory
  formFields?: FormField[]
}

class EditSection extends PureComponent<Props> {
  componentDidMount() {
    const refreshToken = cookies.get('refresh_token')

    auth
      .authenticate(refreshToken)
      .then(token =>
        cookies.set('refresh_token', token.token, {
          path: '/',
        })
      )
      .catch(error => this.props.routeHistory.push(URLS.LOGIN))
  }

  goToPrevious = () => {
    const { routeHistory } = this.props

    if (routeHistory.location.key) {
      routeHistory.goBack()
    } else {
      routeHistory.push(URLS.MOMS)
    }
  }

  render() {
    const {
      name,
      formFields,
      editItem,
      disableButton,
      initialValues,
      deleteItem,
    } = this.props

    return (
      <div className={`edit ${name.toLowerCase()} shape-content form`}>
        <ReactPlaceholder
          type="rect"
          rows={10}
          ready={
            initialValues.defensoraNombre ||
            initialValues.victimaNombre ||
            initialValues.titulo
          }
        >
          <FormNewItem
            initialValues={initialValues}
            name={`${name}FormEdit`}
            formFields={formFields}
            cancelForm={() => this.goToPrevious()}
            editItem={editItem}
            disableButton={disableButton}
            deleteItem={deleteItem}
          />
        </ReactPlaceholder>
      </div>
    )
  }
}
export default EditSection
