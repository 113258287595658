import React, { Component } from 'react'
import { RouterHistory, Match } from 'react-router-dom'
import { connect } from 'react-redux'

import { EditSection, error, success } from '../Components'
import { AppState, alertaActions, alertasActions } from '../state'
import { getFormValues } from 'redux-form'
import { URLS } from '../constants'

type IValForm = {
  titulo: string
  tipo: {
    value: string
    label: string
  }
  text: string
  link: string
  period: {
    startDate: moment.Moment
    endDate: moment.Moment
  }
}

type OwnProps = {
  history: RouterHistory
  match: Match
}

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps
class FlashNewsItem extends Component<Props> {
  componentDidMount() {
    const { slug } = this.props

    if (slug) {
      this.props.fetchAlerta({ slug })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiError, isUpdating, history, isDeleting } = this.props

    if (!isUpdating && prevProps.isUpdating) {
      if (apiError) {
        error('¡¡Upps!!!, Ha habido un error al actualizar correctamente.')
      } else {
        this.props.fetchAlertas()
        success('Alerta editada correctamente.')
        history.push(URLS.FLASH_NEWS)
      }
    }

    if (!isDeleting && prevProps.isDeleting) {
      this.props.fetchAlertas()
      success('Alerta borrado correctamente.')
      history.push(URLS.FLASH_NEWS)
    }
  }

  editItem = () => {
    const { updateAlerta, formValues, slug } = this.props
    const alertaEdit = formValues && {
      titulo: formValues.titulo,
      tipo: formValues.tipo.value,
      texto: formValues.text,
      link: formValues.link,
      fecha_inicio: formValues.period.startDate
        .hour(0)
        .minute(0)
        .second(0)
        .format('YYYY-MM-DD HH:mm'),
      fecha_fin: formValues.period.endDate
        .hour(23)
        .minute(59)
        .second(0)
        .format('YYYY-MM-DD HH:mm'),
    }

    updateAlerta({
      slug,
      body: alertaEdit,
    })
  }

  goToPrevious = () => {
    const { history } = this.props

    if (history.location.key) {
      history.goBack()
    } else {
      history.push(URLS.FLASH_NEWS)
    }
  }

  delete = () => {
    const { deleteAlerta, slug } = this.props

    deleteAlerta(slug)
  }

  render() {
    const { disableButton, initialValues } = this.props

    return (
      <div className="shape-content">
        <div className="shape-row">
          <div className="shape-col-auto">
            <EditSection
              initialValues={initialValues}
              routeHistory={this.props.history}
              name="Alerta"
              cancelForm={() => this.goToPrevious()}
              editItem={this.editItem}
              disableButton={!!disableButton}
              deleteItem={this.delete}
              formFields={[
                {
                  type: 'period',
                  name: 'Periodo en que se muestra',
                  fieldName: 'period',
                },
                { type: 'text', name: 'Título', fieldName: 'titulo' },
                {
                  type: 'select',
                  name: 'Call to Action',
                  fieldName: 'tipo',
                  options: [
                    {
                      value: 1,
                      label: 'Ayúdanos con un tweet',
                    },
                    {
                      value: 2,
                      label: 'Ayúdanos con un like',
                    },
                    {
                      value: 3,
                      label: 'Ayúdanos compartiendo',
                    },
                  ],
                },
                { type: 'text', name: 'Texto', fieldName: 'text' },
                { type: 'link', name: 'Link', fieldName: 'link' },
                {
                  type: 'delete',
                  name: 'Borrar Alerta',
                  fieldName: 'delete',
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const formValues = getFormValues('AlertaFormEdit')(state) as IValForm
  const { match } = ownProps

  const slug = match.params.slug
  const disableButton = formValues && !formValues.titulo

  const alerta = state.alerta.items[0]

  return {
    slug,
    alerta,
    disableButton: disableButton,
    isDeleting: state.alerta.isDeleting,
    apiError: state.alerta.APIError,
    isUpdating: state.alerta.isUpdating,
    formValues,
    initialValues: alerta
      ? {
          titulo: alerta.titulo,
          period: {
            startDate: alerta.inicio,
            endDate: alerta.fin,
          },
          tipo: {
            value: alerta.tipo,
          },
          text: alerta.text,
          link: alerta.link,
        }
      : {},
  }
}
const mapActionsToProps = {
  fetchAlertas: alertasActions.fetchResources,
  fetchAlerta: alertaActions.fetchResources,
  deleteAlerta: alertaActions.deleteResource,
  updateAlerta: alertaActions.updateResource,
}
export default connect(mapStateToProps, mapActionsToProps)(FlashNewsItem)
