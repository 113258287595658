import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css'

type Props = {
  status: (status: boolean) => void
  closeModal: boolean
}

type State = {
  statusModal: boolean
}

class Modal extends PureComponent<Props, State> {
  state = {
    statusModal: false,
  }

  componentDidUpdate(prevProps) {
    const { closeModal } = this.props
    if (closeModal !== prevProps.closeModal) {
      this.setState({ statusModal: false })
    }
  }

  render() {
    const { statusModal } = this.state
    return (
      <div className="shape-modal-new">
        {statusModal ? (
          <div className="shape-modal-new-form">
            <div
              className="shape-modal-new-form-close"
              onClick={() => this.setState({ statusModal: false })}
            >
              <FontAwesomeIcon icon="times-circle" fixedWidth />
            </div>
            {this.props.children}
          </div>
        ) : (
          <div
            className="shape-modal-new-plus"
            onClick={() => this.setState({ statusModal: true })}
          >
            <FontAwesomeIcon icon="plus" fixedWidth />
          </div>
        )}
      </div>
    )
  }
}

export default Modal
