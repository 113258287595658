import React, { Fragment } from 'react'

import './index.css'

type Props = {
  className?: string
  text: {
    type: string
    text?: string
    color?: string
    link?: string
    items?: string[]
  }[]
}

const FormatedDescriptionText = (data: Props) => {
  return (
    <div
      className={` shape-col-12-sm  ${data.className || ''} shape-text-body`}
    >
      <p>
        {data.text.length
          ? data.text.map((t, key) => {
              return (
                <Fragment key={key}>
                  {t.type === 'p' ? (
                    <span className={t.color ? `text-color-${t.color}` : ''}>
                      {t.text}
                    </span>
                  ) : null}

                  {t.type === 'mark' ? (
                    <mark
                      className={t.color ? `bg-color-${t.color}-opacity` : ''}
                    >
                      {t.text}
                    </mark>
                  ) : null}
                </Fragment>
              )
            })
          : null}
      </p>
    </div>
  )
}

export default FormatedDescriptionText
