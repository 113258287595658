import React, { useState } from 'react'
import { Field, WrappedFieldProps } from 'redux-form'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { error } from '../../Toast'

import getConfig from '../../../config'
import postImage from './postImage'
import './index.css'

type OwnProps = {
  image: string
}

type Props = React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps &
  OwnProps

const urlRoot = getConfig().API_URL

function DropzoneField(props: Props) {
  const [status, setStatus] = useState('start')

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: file => {
      setStatus('subiendo')
      postImage(file[0], props.meta.form, handleOnChange)
    },
  })

  const handleOnChange = resp => {
    const {
      input: { onChange },
    } = props
    setStatus('listo')

    resp.then(async response => {
      if (response.error) {
        error(
          'Ha ocurrido un error!, parece que esta imagen es muy grande o NO es .png o .jpg'
        )
      }
      if (response.filePath) {
        onChange(urlRoot.replace('/api/admin', '') + response.filePath)
      }
    })
  }

  return (
    <div className="dropzoneField-container">
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} name="upload-image" />
        {status !== 'subiendo' ? (
          <div className="info">
            <span className="icon">
              <FontAwesomeIcon icon="cloud-upload-alt" />
            </span>
            <span>Arrastra tu imagen</span>
            <span>o da click</span>
            <span> Formato .png o .jpg</span>
          </div>
        ) : (
          <div className="info">
            <span>Subiendo</span>
            <br />
            <FontAwesomeIcon icon="spinner" spin />
          </div>
        )}
      </div>
    </div>
  )
}

const renderFileDrop = (props: Props) => {
  return (
    <div className={`shape-dropzone ${props.image ? 'hasImage' : ''}`}>
      <Field
        component={DropzoneField}
        multiple={false}
        name={props.input.name}
      />
      <div className="current-image">
        {props.image ? <img src={props.image} alt="nvavj-logo" /> : null}
      </div>
    </div>
  )
}

export default renderFileDrop
