import { Field, WrappedFieldProps } from 'redux-form'

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { renderInput, DatePicker } from '..'

import './index.css'

type Props = {
  label: string
} & WrappedFieldProps &
  any

const renderInputArray = ({ fields, meta: { error } }: Props) => {
  return (
    <div className="material-list">
      <div className="section-title">MATERIAL HEMEROGRÁFICO</div>
      {fields.map((field, index) => (
        <div key={index} className="material-item">
          <button
            className="btn-eliminar"
            type="button"
            title="Elimina Material"
            // @ts-ignore
            onClick={() => fields.remove(index)}
          >
            Eliminar <FontAwesomeIcon icon="trash" />
          </button>
          <Field
            label="Fecha de Publicación"
            name={`${field}.fecha`}
            component={DatePicker}
          />
          <Field
            name={`${field}.origen`}
            type="origen"
            component={renderInput}
            label={`Página dónde se publicó`}
          />
          <Field
            name={`${field}.titulo`}
            type="titulo"
            component={renderInput}
            label={`Titulo`}
          />

          <Field
            name={`${field}.url`}
            type="url"
            component={renderInput}
            label={`URL`}
          />
        </div>
      ))}
      {error && <div className="error">{error}</div>}
      <button
        className="btn-agregar"
        type="button"
        onClick={() => fields.push()}
      >
        Agrega Material
      </button>
    </div>
  )
}

export default renderInputArray
