import { WrappedFieldProps } from 'redux-form'

import React from 'react'
import './index.css'

type Props = {
  label: string
} & React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps

const Switch = (props: Props) => {
  const {
    input: { value, onChange, onBlur },
    label,
  } = props

  // Avoid React warnings
  const handleChange = () => {}
  const toggle = () => {
    onChange(!value)
    // @ts-ignore
    onBlur()
  }

  return (
    <div className="shape-switch">
      {label && <label className="shape-switch-name-r">{label}</label>}
      <input type="checkbox" checked={value} onChange={handleChange} />
      <div className="shape-slider" onClick={toggle} />
    </div>
  )
}

export default Switch
