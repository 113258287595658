import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { Table } from '../../index'
import RowField from './RowField'
import { Option } from '../../../types'
import { AppState } from '../../../state'

type OwnProps = {
  formName: string
  name: string
  columns: { id: string; label: string }[]
  options: Option[]
  editRow: (value: string) => void
  deleteRow: (slug: string) => void
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = OwnProps & StateProps

class TableEditDelete extends PureComponent<Props> {
  render() {
    const { name, columns, options, formName, editRow, deleteRow } = this.props

    return (
      <Table
        columns={columns}
        placeholderRows={2}
        render={() => {
          return (
            <>
              <RowField
                label="Tipos De Caso"
                name={`table-${name}-new`}
                formName={formName + '-table'}
                editRow={editRow}
              ></RowField>
              {options && options.length ? (
                options.map((row, key) => (
                  <RowField
                    key={key}
                    label="Tipos De Caso"
                    name={`table-${name}-${key}`}
                    formName={formName + '-table'}
                    editRow={editRow}
                    deleteRow={deleteRow}
                    slug={row.value}
                  ></RowField>
                ))
              ) : (
                <RowField
                  label="Tipos De Caso"
                  name={`table-${name}-new`}
                  formName={formName + '-table'}
                  editRow={editRow}
                ></RowField>
              )}
            </>
          )
        }}
      ></Table>
    )
  }
}

const reduxFormContainer = reduxForm({
  enableReinitialize: true,
  // @ts-ignore
})(TableEditDelete)

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { formName, options, name } = ownProps
  const tableValues = options.map((o, key) => [`table-${name}-${key}`, o.label])
  const initialValues = Object.fromEntries(tableValues)

  return {
    form: formName + '-table',
    initialValues,
  }
}

export default connect(mapStateToProps)(reduxFormContainer)
