import React, { Component } from 'react'
import { RouterHistory } from 'react-router-dom'

import { Section } from '../Components'

type Props = { history: RouterHistory }

class Contacts extends Component<Props> {
  render() {
    return <Section name="Contacts" routeHistory={this.props.history} />
  }
}

export default Contacts
