import React, { PureComponent } from 'react'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { ToastContainer } from 'react-toastify'
import 'moment/locale/es'

import 'react-toastify/dist/ReactToastify.css'

import {
  faSpinner,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faFemale,
  faHeart,
  faArchive,
  faBolt,
  faEnvelope,
  faPlusCircle,
  faTimesCircle,
  faCloudUploadAlt,
  faTrash,
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'

import {
  LogIn,
  Moms,
  Mom,
  Cases,
  Case,
  Multimedia,
  FlashNews,
  Contacts,
} from './Pages'
import { URLS } from './constants'
import { Sidebar } from './Components'

import getConfig from './config'
import { Auth } from './auth'
import API from './api'
import MultimediaItem from './Pages/MultimediaItem'
import FlashNewsItem from './Pages/FlashNewsItem'

library.add(
  faSpinner,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faFemale,
  faHeart,
  faArchive,
  faBolt,
  faEnvelope,
  faPlusCircle,
  faTimesCircle,
  faCloudUploadAlt,
  faTrash,
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faPlus
)

const auth = new Auth(getConfig())
export const api = new API(auth)

type State = {
  sidebar: string
  isAuthed: boolean
}

type Props = {}

class App extends PureComponent<Props, State> {
  state: State = {
    isAuthed: false,
    sidebar: localStorage.getItem('sidebar') || 'open',
  }

  render() {
    const { sidebar } = this.state

    return (
      <div className="App">
        <BrowserRouter>
          <>
            <Sidebar
              status={(status: string) => this.setState({ sidebar: status })}
            />
            <div className={`${sidebar} main-container`}>
              <Switch>
                <Route exact path={URLS.CASES} component={Cases} />
                <Route exact path={URLS.CASE} component={Case} />
                <Route exact path={URLS.MOMS} component={Moms} />
                <Route exact path={URLS.MOM} component={Mom} />
                <Route exact path={URLS.MULTIMEDIA} component={Multimedia} />
                <Route
                  exact
                  path={URLS.MULTIMEDIA_ITEM}
                  component={MultimediaItem}
                />
                <Route exact path={URLS.FLASH_NEWS} component={FlashNews} />
                <Route
                  exact
                  path={URLS.FLASH_NEWS_ITEM}
                  component={FlashNewsItem}
                />
                <Route exact path={URLS.CONTACTS} component={Contacts} />
                <Route exact path={URLS.LOGIN} component={LogIn} />

                <Redirect to={URLS.LOGIN} />
              </Switch>
            </div>
          </>
        </BrowserRouter>
        <ToastContainer position="bottom-right" hideProgressBar={true} />
      </div>
    )
  }
}

export default App
