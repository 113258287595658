import React, { Component } from 'react'
import { connect } from 'react-redux'

import { RouterHistory } from 'react-router-dom'

import { Section, error, success } from '../Components'
import { URLS } from '../constants'
import { AppState, alertaActions, alertasActions } from '../state'
import { getFormValues } from 'redux-form'

const COLUMNS = [
  { id: 'titulo', label: 'Título' },
  { id: 'inicio', label: 'Inicio', type: 'date' },
  { id: 'fin', label: 'Fin', type: 'date' },

  { id: 'editar', label: 'Editar' },
]

type OwnProps = { history: RouterHistory }

type StateProps = ReturnType<typeof mapStateToProps>
type ActionsProps = typeof mapActionsToProps

type Props = OwnProps & StateProps & ActionsProps

type IValForm = {
  titulo: string
  text: string
  link: string
  period: {
    startDate: moment.Moment
    endDate: moment.Moment
  }
  tipo: {
    value: string
    label: string
  }
}
type State = {
  closeModal: boolean
}
class Flashnews extends Component<Props, State> {
  state = {
    closeModal: false,
  }
  componentDidMount() {
    const { alertas } = this.props

    if (!alertas.length) {
      this.props.fetchAlertas()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isCreating, apiError } = this.props
    const { closeModal } = this.state

    if (isCreating && !prevProps.isCreating) {
      if (apiError) {
        this.setState({ closeModal: true }, () =>
          error('¡¡Upps!!!, Ha habido un error alerta.')
        )
      }
      this.props.fetchAlertas()
      this.setState({ closeModal: true }, () =>
        success('Caso creado con éxito')
      )
    }

    if (closeModal && !prevState.closeModal) {
      this.setState({ closeModal: false })
    }
  }

  create = () => {
    const { formValues, createAlerta } = this.props

    const newAlert = {
      titulo: formValues.titulo,
      tipo: formValues.tipo.value,
      texto: formValues.text,
      link: formValues.link,
      fecha_inicio: formValues.period.startDate
        .hour(0)
        .minute(0)
        .second(0)
        .format('YYYY-MM-DD HH:mm'),
      fecha_fin: formValues.period.endDate
        .hour(23)
        .minute(59)
        .second(0)
        .format('YYYY-MM-DD HH:mm'),
    }

    createAlerta(newAlert)
  }

  render() {
    const { alertas, isfetching, disableButton } = this.props
    const { closeModal } = this.state

    return (
      <Section
        name="Alertas"
        itemURL={URLS.FLASH_NEWS_ITEM}
        formFields={[
          {
            type: 'period',
            name: 'Periodo en que se muestra',
            fieldName: 'period',
          },
          { type: 'text', name: 'Título', fieldName: 'titulo' },
          {
            type: 'select',
            name: 'Call to Action',
            fieldName: 'tipo',
            options: [
              {
                value: 1,
                label: 'Ayúdanos con un tweet',
              },
              {
                value: 2,
                label: 'Ayúdanos con un "Me gusta" en Facebook',
              },
              {
                value: 3,
                label: 'Ayúdanos compartiendo',
              },
            ],
          },
          { type: 'text', name: 'Texto', fieldName: 'text' },
          { type: 'link', name: 'Link', fieldName: 'link' },
        ]}
        routeHistory={this.props.history}
        createItem={this.create}
        columns={COLUMNS}
        tableData={alertas}
        closeModal={closeModal}
        isFetching={isfetching}
        disableButton={disableButton}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const formValues = getFormValues('AlertasForm')(state) as IValForm
  const disableButton =
    formValues &&
    formValues.titulo &&
    formValues.period &&
    formValues.tipo &&
    formValues.link

  return {
    disableButton: !disableButton,
    alertas: state.alertas.items,
    isfetching: state.alertas.isFetching,
    isCreating: state.alerta.isCreating,
    apiError: state.alerta.APIError || state.alerta.code,
    formValues,
  }
}
const mapActionsToProps = {
  fetchAlertas: alertasActions.fetchResources,
  createAlerta: alertaActions.createResource,
}
export default connect(mapStateToProps, mapActionsToProps)(Flashnews)
