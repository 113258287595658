import { applyMiddleware, createStore, combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import {
  createResourcesActions,
  createResourcesReducers,
  createResourcesSagas,
} from './resoursesUtils'

import getConfig from '../config'
import API from '../api'
import { Auth } from '../auth'
import { ICasoInitialState, ICasoInitialFromBackend } from './casos/types'
import { ITipoDeCasoState } from './tiposDeCaso/types'
import moment from 'moment'
import { IMultimediaState } from './Multimedia/multimedia'
import { IDefensoraInitialState } from './defensora/types'
import { IAletaInitialState } from './alerta/types'

const RESOURCES = [
  'casos',
  'tiposDeCasos',
  'caso',
  'defensoras',
  'multimedia',
  'multimediaItem',
  'defensora',
  'alerta',
  'alertas',
]

const auth = new Auth(getConfig())
export const api = new API(auth)

interface commonState<T> {
  isFetching: boolean
  isUpdating: boolean
  isCreating: boolean
  isDeleting: boolean
  hasFetched: boolean
  APIError?: string
  code?: string
  items: Array<T>
}

export type AppState = {
  form
  casos: commonState<ICasoInitialState>
  caso: commonState<ICasoInitialFromBackend>
  tiposDeCasos: commonState<ITipoDeCasoState>
  defensoras: commonState<IDefensoraInitialState>
  defensora: commonState<IDefensoraInitialState>
  multimedia: commonState<IMultimediaState>
  multimediaItem: commonState<IMultimediaState>
  alerta: commonState<IAletaInitialState>
  alertas: commonState<IAletaInitialState>
}

export const {
  casosActions,
  casoActions,
  tiposDeCasosActions,
  defensorasActions,
  defensoraActions,
  multimediaActions,
  multimediaItemActions,
  alertaActions,
  alertasActions,
} = createResourcesActions(RESOURCES)

const {
  casosReducer,
  casoReducer,
  tiposDeCasosReducer,
  defensorasReducer,
  defensoraReducer,
  multimediaReducer,
  multimediaItemReducer,
  alertaReducer,
  alertasReducer,
} = createResourcesReducers(RESOURCES, {
  alerta: {
    formatItem: x => ({
      ...x,
      inicio: moment(x.fecha_inicio),
      fin: moment(x.fecha_fin),
    }),
  },
  alertas: {
    formatItem: x => ({
      ...x,
      inicio: moment(x.fecha_inicio),
      fin: moment(x.fecha_fin),
    }),
  },
  casos: {
    formatItem: x => {
      return {
        ...x,
        tipoCaso: x.tipoCaso_id.nombre,
        created: moment(x.created_at),
        historia: {
          ...x.historia,
          blocks: x.historia.blocks
            ? x.historia.blocks.map(block => {
                return {
                  data: block.data,
                  type: block.tool || 'paragraph',
                }
              })
            : [],
        },
      }
    },
  },
  tiposDeCasos: { formatItem: x => x },
  caso: {
    formatItem: x => {
      return {
        ...x,
        tipoCaso: x.tipoCaso_id.nombre,
        created: moment(x.created_at),
        historia: {
          ...x.historia,
          blocks: x.historia.blocks
            ? x.historia.blocks.map(block => {
                return {
                  data: block.data,
                  type: block.tool || 'paragraph',
                }
              })
            : [],
        },
      }
    },
  },
  defensoras: {
    formatItem: x => ({ ...x, created: moment(x.created_at) }),
  },
  multimedia: {
    formatItem: x => ({
      ...x,
    }),
  },
  multimediaItem: {
    formatItem: x => ({
      ...x,
    }),
  },
  defensora: {
    formatItem: x => ({
      ...x,
      descripcion: {
        ...x.descripcion,
        blocks: x.descripcion.blocks
          ? x.descripcion.blocks.map(block => {
              return {
                data: block.data,
                type: block.tool || 'paragraph',
              }
            })
          : [],
      },
    }),
  },
})

const state = combineReducers<AppState>({
  form,
  casos: casosReducer,
  caso: casoReducer,
  tiposDeCasos: tiposDeCasosReducer,
  defensoras: defensorasReducer,
  multimedia: multimediaReducer,
  multimediaItem: multimediaItemReducer,
  defensora: defensoraReducer,
  alerta: alertaReducer,
  alertas: alertasReducer,
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  state,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

createResourcesSagas(sagaMiddleware, api, RESOURCES)

export default store
