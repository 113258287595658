import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'

import './index.css'

type Props = {
  icon: any
  text: string
}

export const Toast = ({ icon, text }: Props) => (
  <Fragment>
    <FontAwesomeIcon icon={icon} />
    <p>{text}</p>
  </Fragment>
)

export const success = (text: string) => {
  return toast(<Toast text={text} icon="check-circle" />, {
    className: 'toastify toastify-success',
  })
}

export const error = (text: string) => {
  return toast(<Toast text={text} icon="exclamation-triangle" />, {
    className: 'toastify toastify-error',
  })
}

export const warning = (text: string) => {
  return toast(<Toast text={text} icon="exclamation-circle" />, {
    className: 'toastify toastify-warning',
  })
}
