import { WrappedFieldProps } from 'redux-form'

import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates'

const START_DATE = 'startDate'
const END_DATE = 'endDate'

type Props = React.InputHTMLAttributes<HTMLInputElement> & WrappedFieldProps

type State = {
  focusedInput: boolean | null
}

export default class Period extends Component<Props, State> {
  state = {
    focusedInput: null,
  }

  handleFocusChange = (focusedInput: boolean) => {
    this.setState({ focusedInput })
  }

  render() {
    const {
      meta: { error, touched },
      input: {
        value: { startDate = null, endDate = null },
        onChange,
        onBlur,
      },
      ...rest
    } = this.props

    const { focusedInput = null } = this.state
    const label = 'Periodo'
    return (
      <div className="form-group">
        {label && <label className="input-name">{label}</label>}
        <div className={focusedInput ? 'DateRangePickerInput__focused' : ''}>
          <DateRangePicker
            startDate={startDate}
            startDateId={START_DATE}
            endDate={endDate}
            endDateId={END_DATE}
            onDatesChange={(...args) => {
              // @ts-ignore
              onChange(...args)
              // @ts-ignore
              onBlur(...args) // toggle 'touched: true'
            }}
            focusedInput={focusedInput}
            onFocusChange={this.handleFocusChange}
            hideKeyboardShortcutsPanel={true}
            minimumNights={0}
            {...rest}
          />
        </div>
        {touched && error && <span className="error">{error}</span>}
      </div>
    )
  }
}
