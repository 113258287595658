import Cookies from 'universal-cookie'
import qs from 'qs'

const cookies = new Cookies()

type AuthenticateReturn = {
  token: string
}

type Config = {
  API_URL: string
  COOKIE_DOMAIN: string
  LOGIN_URL: string
}

type RefreshTokensReturn = {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
  refresh_token: string
}

export class Auth {
  config: Config

  constructor(config: Config) {
    this.config = config
  }

  _getCookieProps = () => {
    return { domain: this.config.COOKIE_DOMAIN }
  }

  _oauthLogout = async (endpoint: string) => {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    console.log(response)
    if (response.status === 200) {
      return response.json()
    }

    throw new Error(response.status.toString())
  }

  _oauthLogin = async (
    endpoint: string,
    data: { correo: string; password: string }
  ) => {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify({
        ...data,
      }),
    })

    if (response.status === 200) {
      return response.json()
    }

    throw new Error(response.status.toString())
  }

  _oauthAccesToken = async (endpoint: string, token: string) => {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.status === 200) {
      return response.json()
    }

    throw new (function() {
      this.code = response.status
    })()
  }

  _getRefreshToken = (): string => {
    return cookies.get('refresh_token')
  }

  _getAccessToken = (): string => {
    return cookies.get('access_token')
  }

  getAccessToken = (): string => {
    return this._getAccessToken()
  }

  login = async (
    correo: string,
    password: string
  ): Promise<AuthenticateReturn> => {
    const endpoint = `${this.config.API_URL}/login`

    return this._oauthLogin(endpoint, {
      correo,
      password,
    })
  }

  logout = async () => {
    const endpoint = `${this.config.API_URL}/logout`
    console.log(endpoint)
    return this._oauthLogout(endpoint)
  }

  authenticateAccesToken = async (
    token: string
  ): Promise<AuthenticateReturn> => {
    const endpoint = `${this.config.API_URL}/get-refresh-token`

    return this._oauthAccesToken(endpoint, token)
  }

  authenticate = async (token: string): Promise<AuthenticateReturn> => {
    const endpoint = `${this.config.API_URL}/get-refresh-token`

    return this._oauthAccesToken(endpoint, token)
  }
}
